import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";
import Container from "../../Components/UI/Container";
import Loader from "../../Components/UI/Loader";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import {
  useGetPublicUserWiseVotedList
} from "../../Web-Hooks/Debate/use-debate";
import Posts from "../Posts/Posts";
import { useGetUserById } from "../../Web-Hooks/Profile/use-profile";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";

const PublicVoted = () => {
  const location = useLocation();
  const { pathname } = location;
  const opponentId = pathname.slice(1).split("/")[1];
  const { user } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();

  const { data: userDetails } = useGetUserById(user?.userId, selectedSubWorkspace?.subWorkSpaceId, selectedWorkspace?.workSpaceId);

  const { data: PublicVotedList, isLoading: isPublicVotedListLoading } =
    useGetPublicUserWiseVotedList(
      user?.userId,
      selectedWorkspace?.workSpaceId,
      opponentId
    );

  return (
    <Container>
      {/* Title */}
      <Suspense fallback={<Loader />}>
        <Posts
          apiType={pathname}
          user={userDetails}
          isLoading={isPublicVotedListLoading}
          AllDebates={PublicVotedList}
        />
      </Suspense>
    </Container>
  );
};

export default PublicVoted;
