import { useState } from "react";
import { Button, Dialog, Spinner, Typography } from "@material-tailwind/react";
import React from "react";
import {
  argumentSuggestionUI,
  categoryValidatorUiError,
  citationValidatorUiError,
  motionValidatorUiError,
  shadowBlue,
} from "../../Utils/Constant";
import Logo from "../../Assets/Images/DB_AI.png";
import pizza from "../../Assets/Images/pizza.jpg";
import Confetti from "../UI/Confetti";
import { convertArrayToString } from "../../Utils/Helper";

export default function AIValidator({
  open,
  debateInfo,
  handleClose,
  actionBtnHandler,
  AIValidatorType,
  showMotionAISuggestions,
  argumentAssistance,
  categoryAssistance,
  isCategoryAssistanceLoading,
  setArgumentHighlight,
}) {
  // eslint-disable-next-line
  const [isVisible, setIsVisible] = useState(false);
  // eslint-disable-next-line
  const [selectedOption, setSelectedOption] = useState(null);

  // console.log(categoryList, "categoryList");
  let userInfo = JSON.parse(localStorage.getItem("user"));
  let content, btn1, btn1Disable;
  // console.log(categoryAssistance, "categoryAssistance");
  // console.log(convertArrayToString(debateInfo?.Fields), "categoryAssistance");
  // Set content and clickEvent based on AIValidatorType
  switch (AIValidatorType) {
    case "Category":
      content = (
        <>
          {categoryAssistance ? (
            <div className="flex flex-col w-full gap-4 justify-center">
              <p className="text-center">
                The motion{" "}
                <span className="font-bold">{debateInfo?.MotionOrClaim}</span>{" "}
                more suited to{" "}
                <span className="font-bold">
                  {!categoryAssistance?.Matched
                    ? categoryAssistance["Appropriate Category"]
                    : convertArrayToString(debateInfo?.Fields)}
                </span>{" "}
                categories
              </p>
              <Typography className="text-center">
                {categoryValidatorUiError}
              </Typography>
            </div>
          ) : (
            <div className=" flex items-center justify-center">
              {" "}
              <Spinner className="h-10 w-10 text-gray-900/50" />
            </div>
          )}
        </>
      );
      btn1 = "Use Suggestion";
      btn1Disable = isCategoryAssistanceLoading;
      break;
    case "Image":
      content = (
        <div className="flex flex-col w-full gap-4 justify-center items-center">
          <p className="text-center">
            The motion{" "}
            <span className="font-bold">{debateInfo?.MotionOrClaim}</span> and
            the categories <span className="font-bold">Food and Health</span>{" "}
            below image is more suitable
          </p>
          <img src={pizza} alt="suggestion " className="w-1/2" />
          <Typography className="text-center">
            Consider using the suggested image that more align with your motion
            so debate is easier to find.
          </Typography>
        </div>
      );
      btn1 = "Use Suggestion";
      break;
    case "Motion":
      content = motionValidatorUiError;
      btn1 = "Show Suggestions";

      break;
    case "Citation":
      content = userInfo?.isPurchasedPlanOfDebate ? (
        <div className="flex flex-col gap-4">
          <p>{citationValidatorUiError}</p>
        </div>
      ) : (
        "Debate citation does not appear to align with the debate content. Consider a citation that matches with the motion and category."
      );
      btn1 = userInfo?.isPurchasedPlanOfDebate
        ? "Show Suggestion"
        : "Change Citation";

      break;
    case "Argument":
      content = (
        <div className="flex flex-col gap-4">
          <p>{argumentSuggestionUI}</p>
          <article className="text-left ">
            {/* {argumentAssistance?.arguments?.output ? (
              Object.entries(
                argumentAssistance?.arguments?.Argument_ideas
              ).map(([key, idea]) => (
                <p key={key} >
                  {key}
                  {". "}
                  {idea}
                </p>
              ))
            ) : (
              <div className=" flex items-center justify-center">
                {" "}
                <Spinner className="h-10 w-10 text-gray-900/50" />
              </div>
            )} */}
            <div className="flex flex-col w-full  gap-3 max-h-80 pb-2 overflow-y-auto">
              {argumentAssistance?.arguments?.output ? (
                <>
                  {Object.values(
                    argumentAssistance?.arguments?.Argument_ideas
                  ).map((item) => (
                    <div
                      key={item}
                      className={`flex flex-row items-center hover:cursor-pointer cursor-pointer px-3 py-1 gap-3 border-2  ${
                        selectedOption === item
                          ? shadowBlue
                          : "border-gray-400 !shadow-black"
                      } rounded-md shadow-sm`}
                      // onClick={() => handleRadioChange(item)}
                    >
                      <span className="mt-2">{item}</span>
                    </div>
                  ))}
                  <div
                    className={`flex flex-row items-center hover:cursor-pointer cursor-pointer px-3 py-1 gap-3 border-2  ${
                      selectedOption === "All"
                        ? shadowBlue
                        : "border-gray-400 !shadow-black"
                    } rounded-md shadow-sm`}
                    // onClick={() => handleRadioChange(item)}
                  >
                    <span className="mt-2">
                      Select all arguments structure{" "}
                    </span>
                  </div>
                </>
              ) : (
                <div className=" flex items-center justify-center">
                  {" "}
                  <Spinner className="h-10 w-10 text-gray-900/50" />
                </div>
              )}
            </div>
          </article>
        </div>
      );
      btn1 = "Copy to Argument";
      break;
    default:
      content = categoryValidatorUiError;
  }

  return (
    <div>
      <Dialog
        open={open}
        className="rounded-[50px] border-gray-500 border-4 flex flex-col gap-3 items-center p-3"
        // size="xs"
      >
        <div className="flex gap-3 justify-between">
          <div className="flex gap-4 items-center">
            <img
              src={Logo}
              alt="Logo"
              height="60"
              width="60"
              className=" rounded-full  shadow-sm "
            />
            <span className="text-primary font-semibold text-5xl">
              Suggestion
            </span>
          </div>
        </div>
        <div className="w-full mt-3 px-3 flex flex-col gap-4">
          <div className="flex flex-col gap-4 min-w-full text-black text-center">
            {content}
          </div>
          <div className="flex flex-col gap-4 items-center my-4">
            <div className="flex gap-4">
              <Confetti isVisible={isVisible} />
              <Button
                className="bg-primary text-black rounded-full border-black border w-48"
                onClick={() => {
                  handleClose();
                  showMotionAISuggestions();
                }}
                disabled={btn1Disable}
              >
                {/* {AIValidatorType !== "Motion"
                  ? "Use Suggestions"
                  : "Show Suggestions"} */}
                {btn1}
              </Button>
              <Button
                variant="outlined"
                className="rounded-full w-48"
                onClick={handleClose}
              >
                Keep Original
              </Button>
            </div>
            {AIValidatorType !== "MotionSuggestion" && (
              <Button
                variant="text"
                className="rounded-full text-debatePalette-link p-0 m-0 hover:bg-white active:bg-white focus:bg-white mt-4"
                onClick={handleClose}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
