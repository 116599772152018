import { Checkbox, Slider } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";
import DebateFooter from "../../../Components/Functionality/DebateFooter";
import DebateHeader from "../../../Components/Functionality/DebateHeader";
import { useCreateDebateContext } from "../../../Context/CreateDebateContext";
import { shadowBlue } from "../../../Utils/Constant";

const SelectCustomRounds = ({ terms,setTerms, handleBack, handleNext }) => {
  const { debateInfo, handleStateUpdate } = useCreateDebateContext();
  const content = {
    headLine: "Debate Rule",
    baseLine: "",
    page: 3,
    pageName: "RoundSelection",
  };

  const rounds = [1, 3, 5, 7, 9];

  const duration = [
    {
      label: "1 Hour",
    },
    {
      label: "1 Day",
    },
    {
      label: "1 Week",
    },
  ];
  return (
    <div className="flex flex-col gap-4">
      <DebateHeader content={content} />
      <p className="my-2 text-lg font-semibold">How many rounds do you want?</p>
      <div className="flex gap-2 md:gap-5">
        {rounds.map((round, i) => (
          <div
            key={round}
            onClick={() =>
              handleStateUpdate({ ...debateInfo, CustomRounds: round })
            }
            className={`cursor-pointer flex items-center justify-center p-4 md:p-4 rounded-md border border-debatePalette-buttonBorder ${
              round === debateInfo?.CustomRounds
                ? `${shadowBlue} border-debatePalette-shadow`
                : ""
            }`}
          >
            {round}
          </div>
        ))}
      </div>
      <p className="my-2 text-lg font-semibold">
        How long do you want each round?
      </p>
      <div className="flex gap-2  md:gap-5">
        {duration.map((dur, i) => (
          <div
            key={i}
            onClick={() =>
              handleStateUpdate({
                ...debateInfo,
                CustomTimeFrame: dur.label,
              })
            }
            className={` cursor-pointer flex items-center justify-center p-4 md:p-4 rounded-md border border-debatePalette-buttonBorder ${
              dur.label === debateInfo?.CustomTimeFrame
                ? `${shadowBlue} border-debatePalette-shadow`
                : ""
            }`}
          >
            {dur.label}
          </div>
        ))}
      </div>
      <p className="my-2 text-lg font-semibold">How many votes to win round?</p>
      <div className="flex flex-col gap-2  md:gap-5">
        <Slider
          color="blue"
          value={
            debateInfo?.CustomeMaxVotesToWin
              ? debateInfo?.CustomeMaxVotesToWin
              : 0
          }
          onChange={(e) =>
            handleStateUpdate({
              ...debateInfo,
              CustomeMaxVotesToWin: parseInt(e.target.value),
            })
          }
          thumbClassName="[&::-webkit-slider-thumb]:bg-debatePalette-shadow [&::-webkit-slider-thumb]:ring-debatePalette-shadow"
        />
        <div className="flex justify-between">
          <span className="font-semibold">
            {debateInfo?.CustomeMaxVotesToWin
              ? debateInfo?.CustomeMaxVotesToWin
              : 0}
          </span>
          <span className="font-semibold">100</span>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <Checkbox
          color="blue"
          checked={terms}
          onChange={() => {
            setTerms(!terms);
          }}
          ripple={false}
        />
        <p className="text-sm text-debatePalette-bodyText">
          I have read and agreed to the{" "}
          <Link
            to={process.env.REACT_APP_PRIVACY_POLICY}
            target="_blank"
            className="text-debatePalette-shadow underline"
          >
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link
            to={process.env.REACT_APP_TERMS_SERVICE}
            target="_blank"
            className="text-debatePalette-shadow underline"
          >
            Terms of use
          </Link>{" "}
          and{" "}
          <Link
            to={process.env.REACT_APP_EULA}
            target="_blank"
            className="text-debatePalette-shadow underline"
          >
            EULA
          </Link>{" "}
          community guidelines.
        </p>
      </div>
      <DebateFooter
        isNext={
          debateInfo?.CustomRounds &&
          debateInfo?.CustomTimeFrame &&
          terms &&
          debateInfo?.CustomeMaxVotesToWin
            ? false
            : true
        }
        handleBack={handleBack}
        handleNext={handleNext}
      />{" "}
    </div>
  );
};

export default SelectCustomRounds;
