import { PlusIcon } from "@heroicons/react/24/solid";
import { Button, Chip } from "@material-tailwind/react";
import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import NoData from "../../Components/Functionality/NoData";
import SortByFilter from "../../Components/Functionality/SortByFilter";
import CitationTagListPopup from "../../Components/Popup/CitationTagListPopup";
import InfoPopup from "../../Components/Popup/InfoPopup";
import CardActionButtons from "../../Components/UI/Buttons/CardActionButtons";
import ArgumentCard from "../../Components/UI/Cards/ArgumentCard";
import Container from "../../Components/UI/Container";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import SegmentedControl from "../../Components/UI/Tabs/DebateRoundTabs";
import Tooltip from "../../Components/UI/Tooltip/Tooltip";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { getLocalTime } from "../../Utils/Helper";
import {
  useGetDebateCitationList,
  useGetDebateDetails,
  useGetDebateDetailsPage,
} from "../../Web-Hooks/Debate/use-debate";
import ReportPopup from "./ReportPopup";

const DebateDetail = () => {
  const { user: loggedInUser } = useAuth();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const location = useLocation();
  const { pathname } = location;
  const [query] = useSearchParams();
  const round = query?.get("round");
  const debateId = pathname.slice(1)?.split("/")[1];
  const [selectSide, setSelectSidePopup] = useState(false);
  const [selectedSide, setSelectedSide] = useState(null);
  const [sortBy, setSortBy] = useState({
    label: "All",
    value: "all",
  });
  const navigate = useNavigate();
  const { data: debateDetails, isLoading: isDebateDetailsLoading } =
    useGetDebateDetailsPage(
      debateId,
      round,
      sortBy.value,
      loggedInUser?.userId
    );
  const { data: citationList } = useGetDebateCitationList(debateId);
  const { data: debate } = useGetDebateDetails(debateId, loggedInUser?.userId);
  const [citationPopup, setCitationPopup] = useState(false);
  const [debate1, setDebate1] = useState(null);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [reportPopup, setReportPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [userId, setUserId] = useState(null);

  const handleSide = (side) => {
    setSelectSidePopup(false);
    setSelectedSide(side);
  };

  const Options = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Agree",
      value: "agree",
    },
    {
      label: "Disagree",
      value: "disagree",
    },
  ];

  const isArgumentAdded = debateDetails?.filterArguments?.find(
    (user) => user?.userId === loggedInUser?.userId
  );

  if (!debateDetails && !isDebateDetailsLoading) {
    return <NoData message={"Debate Not Found"} />;
  }
  if (isDebateDetailsLoading) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  const chipValue = debateDetails?.isDebatePublic
    ? "Public"
    : debateDetails?.isDebateSemiPublic
    ? `${
        debateDetails?.subWorkspaceName
          ? `Semi-Public - ${debateDetails?.subWorkspaceName}`
          : "Semi-Public"
      }`
    : debateDetails?.isDebatePrivate
    ? "Private"
    : "";

  const disabled = !!isArgumentAdded
    ? true
    : debateDetails?.isOtherUserAllow
    ? false
    : true;

  const isCreator = debateDetails?.creatorId === loggedInUser?.userId;

  return (
    <>
      {/* <AddUpdateScalerPopup /> */}
      <ReportPopup
        confirmPopup={confirmationPopup}
        setConfirmPopup={setConfirmationPopup}
        reportPopup={reportPopup}
        setReportPopup={setReportPopup}
        debateId={debateId}
        loggedInUser={loggedInUser}
        selectedSubWorkspace={selectedSubWorkspace}
        selectedWorkspace={selectedWorkspace}
        type={"Debate"}
        popupTitle={popupTitle}
        setPopupTitle={setPopupTitle}
        userId={userId}
      />
      <CitationTagListPopup
        debate={debate1}
        citationPopup={citationPopup}
        setCitationPopup={setCitationPopup}
        setDebate={setDebate1}
      />
      <section className="overflow-y-auto flex w-full flex-col px-4 lg:px-8 py-4 justify-start items-start md:h-[calc(100dvh-8rem)]">
        <div className="flex relative flex-col h-full justify-start w-full gap-2">
          <SegmentedControl
            currentRound={
              round || debateDetails?.currentRound
                ? JSON.parse(debateDetails?.currentRound)
                : 0
            }
            totalRounds={
              debateDetails?.totalRound
                ? JSON.parse(debateDetails?.totalRound)
                : 0
            }
          />
          <div className="w-full flex justify-between">
            {chipValue && (
              <div className="flex gap-2 items-center">
                <Chip
                  value={chipValue}
                  className={
                    debateDetails?.isDebatePublic
                      ? "bg-public text-publicText"
                      : debateDetails?.isDebateSemiPublic
                      ? "bg-semiPublic text-semiPublicText"
                      : "bg-private text-privateText "
                  }
                />
                <span className="h-1 w-1 bg-debatePalette-title rounded-full"></span>
                <span>{getLocalTime(debateDetails?.createdAt)}</span>
              </div>
            )}
            <div className="flex gap-4">
              <SortByFilter
                Options={Options}
                setOption={setSortBy}
                defaultValue={sortBy}
              />
              {/* <Button
                disabled={debateDetails?.debateType === "eonian" ? false : disabled}
                className={`p-0 rounded-full  ${
                  debateDetails?.debateType === "eonian"
                    ? "bg-debatePalette-shadow hover:bg-debatePalette-shadow"
                    : disabled
                    ? "bg-debatePalette-border"
                    : "bg-debatePalette-shadow hover:bg-debatePalette-shadow"
                }`}
                variant="text"
              >
                <PlusIcon
                  className="text-debatePalette-background rounded-full bg-inherit  h-10 w-10 cursor-pointer"
                  onClick={() => {
                    if (debateDetails?.debateType === "eonian" && isCreator) {
                      navigate(
                        `${pathname}/add-argument?isOtherUserSide=${debateDetails?.sideForDebate}`
                      );
                      return;
                    }
                    setSelectSidePopup(true);
                  }}
                />
              </Button> */}
              <Tooltip
                content={"Add argument"}
                position="left"
                className="w-28"
              >
                <Button
                  disabled={
                    debateDetails?.debateType === "eonian" &&
                    debateDetails?.isOtherUserAllow
                      ? false
                      : disabled
                  }
                  size="sm"
                  className="bg-debatePalette-shadow rounded-full p-2 flex gap-2 items-center text-debatePalette-background hover:bg-debatePalette-shadow"
                  variant="text"
                  onClick={() => {
                    if (debateDetails?.debateType === "eonian" && isCreator) {
                      navigate(
                        `${pathname}/add-argument?roundNo=0&isNextRound=true&userName=Add Argument`
                      );
                      return;
                    }
                    const filteredCitation = citationList?.filter(
                      (citation) => {
                        return citation?.createdByUserId === loggedInUser?.userId;
                      }
                    );
                    const loggedInUserSide = filteredCitation?.length > 0 ? filteredCitation[0]?.bySide : debateDetails?.loginUserSide

                    if (loggedInUserSide) {
                      setSelectSidePopup(false);
                      setSelectedSide(loggedInUserSide);
                      return;
                    }
                    setSelectSidePopup(true);
                  }}
                >
                  <PlusIcon className="w-6 h-6 stroke-2" />
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className="flex w-full justify-between items-end">
            <p className="title flex-1 text-2xl text-wrap text-debatePalette-title font-bold">
              {debateDetails?.motionOrClaim}
            </p>
            {/* <div className="w-auto h-auto lg:w-56">
              <GaugeComp percentage={0} totalVotes={10} />
            </div> */}
          </div>

          {debateDetails?.filterArguments?.map((argument, i) => (
            <ArgumentCard
              key={
                debateDetails?.debateType === "eonian"
                  ? argument?.argumentId || argument?.invitationId
                  : argument?.userId
              }
              debate={{
                ...argument,
                selectedRound: round ? round : debateDetails?.currentRound,
                totalRound: debateDetails?.totalRound,
                currentRound: debateDetails?.currentRound,
                debateId: debateDetails?.debateId,
                isOtherUserAllow: debateDetails?.isOtherUserAllow, 
                subworkspaceId: debateDetails?.subworkspaceId,
                isArgumentAdded: !!isArgumentAdded,
                debateType: debateDetails?.debateType,
                isCreator: debateDetails?.creatorId === loggedInUser?.userId,
              }}
              setSelectSidePopup={setSelectSidePopup}
              setConfirmPopup={setConfirmationPopup}
              setReportPopup={setReportPopup}
              setPopupTitle={setPopupTitle}
              popupTitle={popupTitle}
              setUserId={setUserId}
            />
          ))}
          <div className="sticky bottom-0 w-full flex h-12 gap-5 bg-[#EAEAEA] justify-center rounded-md ">
            <CardActionButtons
              istooltip={`${true}`}
              debate={{
                category: debate?.fields,
                Type: debateDetails?.debateType,
                isDebateSaved: debateDetails?.isDebateSaved,
                subworkspaceId: debateDetails?.subworkspaceId,
                ...debateDetails,
              }}
              setCitationPopup={setCitationPopup}
              setDebate={setDebate1}
            />
          </div>
        </div>
        {selectSide && (
          <InfoPopup
            open={selectSide}
            handleClose={() => setSelectSidePopup(false)}
            title={"DebateBase"}
            btn1={"Agree"}
            btn2={"Disagree"}
            onClickBtn1={() => handleSide("agree")}
            onClickBtn2={() => handleSide("disagree")}
            description={`Motion: ${debateDetails?.motionOrClaim}`}
            description2={" Select Your Debate Side"}
          />
        )}
        {selectedSide && (
          <InfoPopup
            open={selectedSide}
            handleClose={() => setSelectedSide(null)}
            title={"DebateBase"}
            btn1={"Cancel"}
            btn2={"Yes"}
            onClickBtn1={() => setSelectedSide(null)}
            onClickBtn2={() => {
              navigate(
                `${pathname}/add-argument?isOtherUserSide=${selectedSide}`
              );
              setSelectedSide(null);
            }}
            description={`Motion: ${debateDetails?.motionOrClaim}`}
            description2={`Are you sure you want to select ${selectedSide?.toLowerCase()} side`}
            btn1Variant={"outline"}
          />
        )}
      </section>
    </>
  );
};

export default DebateDetail;
