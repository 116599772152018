import { Checkbox, Drawer, Switch, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import Container from "../../Components/UI/Container";
import {
  useGetNotificationStatusWorkspaceWise,
  useUpdateNotificationList,
} from "../../Web-Hooks/Notification/use-notification";
import { useAuth } from "../../Context/AuthContext";

const NotificationDrawer = ({ openNotification, closeOpenNotification }) => {
  const [notificationOn, setNotificationOn] = useState(true);
  const handleCloseDrawer = () => {
    closeOpenNotification();
  };
  const { user } = useAuth();

  const { data: workspaceStatusList } =
    useGetNotificationStatusWorkspaceWise(user?.userId);
  const { mutateAsync: updateStatus} =
    useUpdateNotificationList();


    const filteredToggledOff = workspaceStatusList?.find((workspace)=> workspace?.isToogleOn === false)
    const defaultAllChecked = filteredToggledOff ? false : true

  const handleUpdateStatus = (workspace) => {
    const workspaceObj = {
      WorkSpaceInvitationId: workspace?.workSpaceInvitationId,
      IsToogleOn: workspace?.isToogleOn ? false : true,
    };

    updateStatus(workspaceObj)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Drawer
      placement="right"
      open={openNotification}
      onClose={handleCloseDrawer}
      className={`py-4 sm:w-[500px] ${
        openNotification ? "shadow-xl shadow-debatePalette-base" : ""
      } `}
      overlay={false}
      size={350}
    >
      <Container>
        <form className="flex flex-col gap-4">
          <Switch
            id="notification"
            color="blue"
            checked={notificationOn}
            label={
              <div>
                <Typography color="blue-gray" className="font-medium">
                  Notification On/Off
                </Typography>
                <Typography
                  variant="small"
                  color="gray"
                  className="font-normal"
                >
                  Turn on or off notifications
                </Typography>
              </div>
            }
            onClick={() => setNotificationOn(!notificationOn)}
            onChange={() => setNotificationOn(!notificationOn)}
            containerProps={{
              className: "-mt-5",
            }}
          />

          {notificationOn && (
            <>
              <Switch
                color="blue"
                id="all"
                label={
                  <div>
                    <Typography color="blue-gray" className="font-medium">
                      Select All
                    </Typography>
                  </div>
                }
                defaultChecked={defaultAllChecked}
                containerProps={{
                  className: "",
                }}
              />
              <div className="flex flex-col gap-2">
                {workspaceStatusList?.map((workspace, index) => (
                  <Checkbox
                    color="blue"
                    label={workspace?.workSpaceName}
                    defaultChecked={workspace?.isToogleOn}
                    key={workspace?.workSpaceInvitationId}
                    onClick={() => handleUpdateStatus(workspace)}
                  />
                ))}
              </div>
            </>
          )}
        </form>
      </Container>
    </Drawer>
  );
};

export default NotificationDrawer;
