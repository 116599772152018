import { CSVLink } from "react-csv";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import Tooltip from "../Components/UI/Tooltip/Tooltip";

const DownloadSampleCSV = () => {
  // Sample CSV data (example fields: name, email, phone)
  const sampleData = [
    { first_name: "John Doe", email: "john.doe@example.com", phone: "1234567890" },
    { first_name: "Jane Smith", email: "jane.smith@example.com", phone: "0987654321" },
  ];

  // CSV headers (optional)
  const headers = [
    { label: "first_name", key: "first_name" },
    { label: "email", key: "email" },
  ];

  return (
    <div className="flex items-center gap-3">
      {/* Tooltip wrapping CSV download */}
      <Tooltip content="Download Sample CSV" placement="top" className="text-nowrap">
        <CSVLink
          data={sampleData}  // Data for the sample CSV
          headers={headers}   // Headers for the CSV (optional)
          filename={"sample_contacts.csv"}  // Name of the file
          className="btn btn-primary flex items-center gap-2"
          target="_blank"
        >
          {/* Download Icon */}
          <ArrowDownTrayIcon className="h-5 w-5 text-blue-500" />
        </CSVLink>
      </Tooltip>
    </div>
  );
};

export default DownloadSampleCSV;
