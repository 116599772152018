import React from "react";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useGetSavedReferenceList } from "../../Web-Hooks/Reference/use-reference";
import ReferenceList from "../Reference/ReferenceList";

const SavedReferenceList = () => {
  const { user: loggedInUser } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  // const [filter, setFilters] = useState({
  //   forum: null,
  //   workspace: null,
  // });

  // const subId = filter?.forum ? filter?.forum?.value : "";

  // const workId = filter?.workspace
  //   ? filter?.workspace?.value
  //   : selectedWorkspace?.workSpaceId;

  // const { data: workSpaces } = useWorkspacesByUserId(loggedInUser?.userId);

  // const { data: subWorkSpaces } = useQuery({
  //   queryKey: [filter.workspace?.value, loggedInUser?.userId],
  //   queryFn: async () => {
  //     const res = await getSubWorkspaceByWorkId(
  //       filter.workspace?.value,
  //       loggedInUser?.userId
  //     );
  //     return res;
  //   },
  //   select: (data) => {
  //     return data.listOfSubworkspaces;
  //   },
  //   enabled: !!filter.workspace?.value || !!loggedInUser?.userId,
  // });

  const { data: referenceList, isLoading: isReferenceListLoading } =
    useGetSavedReferenceList(loggedInUser?.userId, selectedWorkspace.workSpaceId, "");


  // const WorkspaceOptions =
  //   workSpaces?.length > 0
  //     ? workSpaces?.map((work) => ({
  //         label: work?.workSpaceName,
  //         value: work?.workSpaceId,
  //       }))
  //     : [];

  // const forumOptions =
  //   subWorkSpaces?.map((subWorkspace) => {
  //     return {
  //       label: subWorkspace.subWorkSpaceName,
  //       value: subWorkspace.subWorkSpaceId,
  //     };
  //   }) || [];

  // useEffect(() => {
  //   setFilters({
  //     workspace: {
  //       label: selectedWorkspace?.workSpaceName,
  //       value: selectedWorkspace?.workSpaceId,
  //     },
  //   });
  // }, [selectedWorkspace]);

  return (
    <div className="h-full flex flex-col gap-5">
      <div className="flex w-full justify-end">
        {/* <SearchFilter
          showFilters={["workspace", "forum"]}
          filters={
            filter?.workspace?.label
              ? filter
              : {
                  ...filter,
                  workspace: {
                    label: selectedWorkspace?.workSpaceName,
                    value: selectedWorkspace?.workSpaceId,
                  },
                }
          }
          setFilters={setFilters}
          forumOptions={[...forumOptions]}
          workspaceOptions={WorkspaceOptions}
        /> */}
      </div>
      <div className="flex flex-col gap-2 h-full overflow-y-auto">
        <ReferenceList
          user={loggedInUser}
          referenceList={referenceList}
          isReferenceLoading={isReferenceListLoading}
        />
      </div>
    </div>
  );
};

export default SavedReferenceList;
