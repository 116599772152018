import { Button, Card, CardBody, Chip } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import { getLocalTime } from "../../../Utils/Helper";
import MotionChip from "../Chip/MotionChip";
import { getDebateDetails } from "../../../api/debate/debate";

const ActiveCard = ({ post, isDraft = false, isOpen = false }) => {
  const accountPreference = post?.isPublic
    ? "public"
    : post?.isSemiPublic
    ? "semipublic"
    : "private";
  const navigate = useNavigate();
  const { user } = useAuth();
  const isCreator = post?.creatorId === user?.userId;

  const handleClick = async() => {
    if (post?.debateType === "eonian" && !isDraft && post?.isUserTurn) {
      isCreator
        ? navigate(
            `/debate/${
              post?.debateId
            }/add-argument?roundNo=${0}&isNextRound=true&userName=Add Argument`
          )
        : navigate(
            `/debate/${post?.debateId}/add-argument?userName=Add Argument&isAddPhoto=false`
          );
      return;
    }
    if (isOpen) {
      const debateDetails = await getDebateDetails(post?.debateId, user?.userId);
      if(post?.debateType === "eonian") {
        navigate(`/debate/${post?.debateId}?round=${0}&isUpdateVote=true`, {
          state: { debate: post, category: debateDetails?.fields, debateType: debateDetails?.type },
        });
        return;
      }
      navigate(`/debate/${post.debateId}?round=${post?.currentRound || 1}&isUpdateVote=true`, {
        state: { debate: post, category: debateDetails?.fields, debateType: debateDetails?.type },
      });
      return;
    }
    if (isDraft) {
      navigate(
        `/create-debate?from=draft&activeState=2&debateId=${post?.debateId}`,
        {
          state: {
            selectedDebate: {
              debateId: post?.debateId,
              MotionOrClaim: post?.motionAndClaim,
              Fields: post?.category,
              IsDebateSemiPublic: post?.isSemiPublic,
              IsDebatePublic: post?.isPublic,
              IsDebatePrivate: post?.isPrivate,
              Type: post?.debateType,
              CreatorOpeningArgument: post?.creatorArgument,
              SideForDebate: post?.sideForDebate,
              DebateImage: post?.creatorDebateImage,
            },
          },
        }
      );
      return;
    }
    navigate(`/debate-results/${post?.debateId}`);
  };
  return (
    <div className="flex flex-col gap-2">
      <Card
        shadow={false}
        className="w-full px-2 rounded-md cursor-pointer  flex-col sm:flex-row items-center justify-center sm:items-stretch "
        onClick={isDraft || isOpen ? handleClick : () => {}}
      >
        <CardBody className="flex md:flex-row flex-col items-start md:items-center w-full justify-between py-1 px-0 gap-3">
          <div className="flex items-center gap-3">
            <MotionChip item={post?.categoryList} />
            <div className="flex flex-col">
              <p className="text-base font-medium text-debatePalette-title">
                {post?.motionAndClaim} - {" "}
                <span className="text-primary">{post?.subworkspaceName}</span>
              </p>
            </div>
          </div>
          <div
            className={`flex ${
              isDraft ? "flex-row" : "md:flex-col"
            } gap-3 md:items-end justify-between w-full md:w-auto`}
          >
            {" "}
            <Chip
              color={accountPreference === "public" ? "green" : "red"}
              value={accountPreference}
              className={
                accountPreference === "public"
                  ? "bg-green-100 text-green-800"
                  : "bg-red-100 text-red-800"
              }
            />
            {isDraft ? (
              <span className={`h-auto`}>{getLocalTime(post?.createdAt)}</span>
            ) : (
              <>
                {post?.debateType === "eonian"  && post?.isUserTurn && (
                  <Button
                    size="sm"
                    className="h-auto bg-secondary"
                    onClick={handleClick}
                  >
                    Add new argument
                  </Button>
                )}
                {post?.debateType !== "eonian" && (
                  <span
                    className={`capitalize cursor-pointer hover:underline h-auto ${
                      post?.debateStatus === "published"
                        ? "text-debatePalette-shadow"
                        : "text-[#1F9254]"
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick();
                    }}
                  >
                    {post?.debateStatus}
                  </span>
                )}
              </>
            )}
          </div>
        </CardBody>
      </Card>
      <hr className="border-debatePalette-buttonBorder" />
    </div>
  );
};

export default ActiveCard;
