import React from "react";
import ActionButton from "../../Components/UI/Buttons/ActionButton";
import DocImage from "../../Assets/Images/doc.png";
import PDFImage from "../../Assets/Images/pdf.png";
import URLImage from "../../Assets/Images/url.png";
import VideoImage from "../../Assets/Images/video.png";
import { getFileType } from "../../Utils/Helper";
// import { Typography } from "@material-tailwind/react";

const LibraryCard = ({ Actions, Citation, menuChildren = <></> }) => {
  const formatCitation = (citation) => {
    let title = "";
    let description = "";

    if (citation?.refFileType === "url") {
      // Assuming title and description are separated by 'Title:' and 'Description:' in citationNote
      const noteParts = citation?.note?.split("\n");
      title = noteParts[0]?.replace("Title: ", "").trim() || "";
      description = noteParts[1]?.replace("Description: ", "").trim() || "";
    } else {
      // Extract the file name from the URL as the title
      const apiTitle = citation?.refUrl?.split("/").pop();
      title = apiTitle?.split("_")?.slice(1)?.join(" ");
      description = citation.note;
    }

    return { title, description };
  };

  const { title, description } = formatCitation({
    ...Citation,
    citationType: Citation?.refFileType,
  });

  const fileType = getFileType(`hello.${Citation?.refFileType}`);
  const Src =
    fileType === "doc"
      ? DocImage
      : fileType === "pdf"
      ? PDFImage
      : Citation?.refFileType === "url"
      ? URLImage
      : fileType === "video"
      ? VideoImage
      : Citation?.refUrl;

  const handleClick = (citation) =>{
    if(citation?.refFileType !== "url"){
      window.open(citation.refUrl, "_blank")
      return;
    }

    const refUrl = citation?.refUrl?.split("debatemodule/")?.pop()
    window.open(refUrl, "_blank")
    return;
  }
  return (
    <div className="flex gap-2 w-full items-center">
      <figure className="w-14">
        <img
          className="h-12 w-12 rounded-md object-cover object-center cursor-pointer"
          src={Src}
          alt={Citation?.refFileType}
          onClick={()=>handleClick(Citation)}
        />
      </figure>

      <div className="px-2 py-1 text-wrap rounded-md flex-1 border border-debatePalette-buttonBorder">
        <span className="line-clamp-1 text-debatePalette-title font-bold">Title: {" "}{title}</span>
        <span className="line-clamp-2">Description: {" "}{description}</span>
      </div>
      {Actions &&
        Actions?.map((act) => (
          <ActionButton
            key={act.title}
            SVG={act.SVG}
            title={act.title}
            istooltip={false}
            isText={false}
            isDisable={act.isDisable}
            onClick={() => act?.handleClick(Citation)}
          />
        ))}
      {menuChildren}
    </div>
  );
};

export default LibraryCard;
