import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import ApplePayPayment from "../../Components/Functionality/ApplePayPayment";
import CardPayment from "../../Components/Functionality/CardPayment";
import DebateHeader from "../../Components/Functionality/DebateHeader";
import Container from "../../Components/UI/Container";

export default function PaymentGateWay() {
  const stripePromise = loadStripe(
    `pk_test_51PLvFcGqYtm6PAKxRl2nNtZGTGsPdnyJ1cFOd52vmCnyyPTw83fKQUYvSuqCalDcM2Pr7JiWAIRoVzgXMpJwrZO6000AaaqJPp`
  );
  const options = {
    mode: "payment",

    amount: 1099,
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      theme: "stripe",
    },
  };
  return (
    <Container>
      <DebateHeader content={{ headLine: "Payment" }} />
      <Elements stripe={stripePromise} options={options}>
        <section className="w-full py-5 md:w-[70%] flx flex-col gap-5">
          <div>
            {" "}
            <ApplePayPayment />
          </div>
          <div>
            {" "}
            <CardPayment />
          </div>
        </section>
      </Elements>
    </Container>
  );
}
