import React from "react";
import Select, {components} from "react-select";

const BasicSelect = ({ Options, defaultValue, value, placeholder, loading = false, onChange, disabled = false,optionForDisable, renderMenuList }) => {
  return (
    <div className="flex gap-2 items-center w-full">
      { <Select
        components={{
          IndicatorSeparator: () => null,
          Menu: (props) => <components.Menu className="!z-10"  {...props} />,
          MenuList: renderMenuList ? renderMenuList : (props) => <components.MenuList  {...props} />,
        }}
        onChange={onChange}
        className="basic-single w-full"
        placeholder={placeholder}
        classNamePrefix="select"
        value={value}
        isLoading={loading}
        isClearable={false}
        isRtl={false}
        isSearchable={false}
        name="sortBy"
        options={Options}
        // noOptionsMessage={"No options available"}
        isDisabled={disabled}
        isOptionDisabled={(option) => option[optionForDisable] === false}
      />}
    </div>
  );
};

export default BasicSelect;
