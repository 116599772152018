import React from 'react';
import NoDebateImage from "../../Assets/Images/NoDebate.png";
import NoDataImage from "../../Assets/Images/NoData.jpg";
import BasicButton from '../UI/Buttons/BasicButton';
import Container from '../UI/Container';
import { Avatar } from '@material-tailwind/react';

// Create context for NoData
const NoDataContext = React.createContext({
  message: undefined,
  handleClick: undefined,
  greet: undefined,
});

// NoData component
const NoData = ({ message, greet, children, isNoData = false, size }) => {
  return (
    <NoDataContext.Provider value={{ message,   greet }}>
      <Container>
        <div className={`flex flex-col gap-2 justify-center items-center rounded-md relative px-10 ${size ? size : "h-full w-full" }`}>
          <Avatar variant='rounded' src={isNoData ? NoDataImage : NoDebateImage} alt='NoData' className={`${isNoData ? "md:min-h-full md:min-w-full" : "md:min-h-full md:min-w-full"} object-contain`} />
          {greet && <p className='text-secondary text-xl font-bold'>{greet}</p>}
          <p className='text-secondary text-xl font-bold'>{message}</p>
          {children}
        </div>
      </Container>
    </NoDataContext.Provider>
  );
};

export default NoData;

// NoData.Buttons component
NoData.Buttons = function NoDataButtons({btn, handleClick}) {
  return (
    <div className="flex gap-2">
      <BasicButton color="secondary" className="px-4 my-1.5 text-md capitalize" onClick={handleClick}>
        {btn}
      </BasicButton>
    </div>
  );
};
