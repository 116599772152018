import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardBody,
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import InputField from "../../Layout/InputField";
import { getUTCDate } from "../../Utils/Helper";
import { useGetRolesCategoriesList } from "../../Web-Hooks/Role/use-roles";
import {
  useAddSingleUserToSubWorkspace,
  useEditUserToSubWorkspace,
} from "../../Web-Hooks/Workspace/use-subworkspace";
import BasicButton from "../UI/Buttons/BasicButton";
import DatePicker from "../UI/DatePicker/DatePicker";
import BasicSelect from "../UI/Select/BasicSelect";
import Tooltip from "../UI/Tooltip/Tooltip";

const AddEditRole = ({
  handleClose,
  open,
  profile,
  isAddNewMember = false,
  setProfile,
  popupFor,
}) => {
  const {
    data: rolesCategoriesList,
    loading: isLoading,
    // error: followersError,
  } = useGetRolesCategoriesList();
  const { user: loggedInUser } = useAuth();
  const options =
    !isLoading && rolesCategoriesList?.length > 0
      ? rolesCategoriesList?.map((role) => ({
          label: role?.name,
          value: role?.name,
        }))
      : [];

  const roleOptions = options?.filter(
    (opt) => opt?.value === "Member" || opt?.value === "Manager"
  );

  const { selectedSubWorkspace, subWorkspaceList } =
    useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const forumOptions = profile.subworkspaceList?.map((item) => ({
    label: item?.subworkspaceName,
    value: item?.subworkspaceId,
    role: item?.role,
    isAccept: item?.isAccept,
  }));

  function findSubWorkspacesForUser(listOfSubworkspaces, loggedInUserId) {
    return listOfSubworkspaces?.filter(subWorkspace => 
        subWorkspace?.managedBy.some(manager => 
            manager.userId === loggedInUserId && 
            (manager.role === "Manager" || manager.role === "Admin")
        )
    );
}

  const forumFilterOption = findSubWorkspacesForUser(subWorkspaceList, loggedInUser?.userId);
  
  const forumOptionsForWorkspace = forumFilterOption?.map((item) => ({
    label: item?.subWorkSpaceName,
    value: item?.subWorkSpaceId,
  }));

  const defaultSubworkspace = forumOptions?.find((opt) =>
    popupFor === "subworkspace"
      ? opt?.value === selectedSubWorkspace?.subWorkSpaceId
      : popupFor === "management"
      ? true
      : opt?.value === opt?.isAccept || selectedSubWorkspace?.subWorkSpaceId
  );

  const defaultRole = options?.find(
    (opt) => opt?.value === defaultSubworkspace?.role
  );

  const [selectedForum, setSelectedForum] = useState(defaultSubworkspace);

  const [selectedRole, setSelectedRole] = useState(defaultRole);

  const {
    mutateAsync: addUserToSubWorkspace,
    isPending: addUserToSubWorkspacePending,
  } = useAddSingleUserToSubWorkspace();

  const {
    mutateAsync: editUserToSubworkspace,
    isPending: editUserToSubWorkspacePending,
  } = useEditUserToSubWorkspace();

  const handleStateReset = () => {
    setSelectedForum(null);
    setSelectedRole(null);
  };

  const handleAddUser = async () => {
    if(profile?.email?.toLowerCase() === loggedInUser?.email?.toLowerCase()){
      toast.error(`You are already added please enter another email`);
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(profile?.email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    const newUser = {
      LoginUserId: loggedInUser?.userId,
      //"UserId":"9be1d189-9bd6-4347-aa92-0ae54d7aaedc",
      Name: profile?.userName,
      Email: profile?.email,
      RoleName: selectedRole?.value || defaultRole?.value || "Member",
      CreatedAt: getUTCDate(),
      SubWorkSpaceId: selectedForum?.value || defaultSubworkspace?.value,
    };

    if (Object.values(newUser).some((value) => !value)) {
      toast.error(`Please fill all the required fields`);
      return;
    }

    addUserToSubWorkspace(newUser)
      .catch((err) => {
        console.log(err);
      })
      .finally(async () => {
        handleClose();
        // handleStateUpdate();
        popupFor === "subworkspace" && handleStateReset();
      });
  };

  const handleEditUser = async () => {
    const updatedUser = {
      LoginUserId: loggedInUser?.userId,
      UserId: profile?.userId,
      RoleName: selectedRole?.value || defaultRole?.value,
      CreatedAt: getUTCDate(),
      SubWorkSpaceId: selectedForum?.value || defaultSubworkspace?.value,
    };
    editUserToSubworkspace(updatedUser)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        handleClose();
        // popupFor === "subworkspace" && handleStateUpdate();
        handleStateReset();
      });
  };

  // const handleStateUpdate = () => {
  //   const isManager = selectedRole?.value === "Manager";
  //   if (popupFor === "subworkspace") {
  //     if (isManager) {
  //       const managedBy = selectedSubWorkspace?.managedBy || [];
  //       const existingIndex = managedBy.findIndex(
  //         (item) => item.userId === profile?.userId
  //       );
  //       if (existingIndex === -1) {
  //         managedBy.push({
  //           userId: profile?.userId,
  //           userName: profile?.userName,
  //           role: selectedRole?.value,
  //         });
  //       }
  //       selectSubWorkspace({
  //         ...selectedSubWorkspace,
  //         managedBy,
  //       });
  //     } else {
  //       const managedBy = (selectedSubWorkspace?.managedBy || []).filter(
  //         (item) => item.userId !== profile?.userId
  //       );
  //       selectSubWorkspace({
  //         ...selectedSubWorkspace,
  //         managedBy,
  //       });
  //     }
  //   } else {
  //     if (isManager) {
  //       const managedBy = selectedWorkspace?.managedBy || [];
  //       const existingIndex = managedBy?.findIndex(
  //         (item) => item.userId === profile?.userId
  //       );
  //       if (existingIndex === -1) {
  //         managedBy.push({
  //           userId: profile?.userId,
  //           userName: profile?.userName,
  //           role: selectedRole?.value,
  //         });
  //       }
  //       selectWorkspace({
  //         ...selectedWorkspace,
  //         managedBy,
  //       });
  //     } else {
  //     }
  //   }
  // };

  const handleChange = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const handleForumChange = (e) => {
    const findRole = options?.find((opt) => opt?.value === e?.role);
    setSelectedForum(e);
    setSelectedRole(findRole);
  };

  // useEffect(() => {
  //   handleForumChange(defaultSubworkspace);
  // }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        handleStateReset();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className=" min-w-[0%] md:min-w-[0%] lg:min-w-[0%] 2xl:min-w-[0%] max-w-[80%] md:max-w-[60%] lg:max-w-[40%] 2xl:max-w-[25%] w-auto md:w-auto lg:w-auto 2xl:w-auto"
      size="xs"
    >
      <DialogHeader
        id="alert-dialog-title"
        className="w-full flex flex-col justify-center items-center gap-2 border-b border-debatePalette-buttonBorder"
      >
        <Typography className="text-2xl font-semibold text-debatePalette-title">
          Role Management
        </Typography>
        <Tooltip content={popupFor === "subworkspace"
            ? selectedSubWorkspace?.subWorkSpaceName
            : selectedWorkspace?.workSpaceName}>
        <Typography className="text-lg xs:line-clamp-1 font-semibold text-debatePalette-title">
          {popupFor === "subworkspace"
            ? selectedSubWorkspace?.subWorkSpaceName
            : selectedWorkspace?.workSpaceName}
        </Typography>
        </Tooltip>
      </DialogHeader>
      <DialogBody className="w-full bg-gray-100 rounded-lg">
        <Card
          className="border border-debatePalette-buttonBorder"
          shadow={false}
        >
          <CardBody className="py-0">
            <>
              <div className="flex border-b border-debatePalette-buttonBorder gap-2 items-center py-3">
                <label
                  htmlFor="name"
                  className="min-w-20 text-sm text-debatePalette-title font-semibold"
                >
                  Name
                </label>
                <InputField
                  inputName={"userName"}
                  className={"border-debatePalette-buttonBorder"}
                  containerClassName={"!min-w-0"}
                  onChange={(e) => handleChange(e)}
                  value={profile?.userName}
                  disable={isAddNewMember ? false : true}
                />
              </div>
              <div className="flex border-b border-debatePalette-buttonBorder gap-2 items-center py-3">
                <label
                  htmlFor="email"
                  className="min-w-20 text-sm text-debatePalette-title font-semibold"
                >
                  Email
                </label>
                <InputField
                  inputName={"email"}
                  className={"border-debatePalette-buttonBorder"}
                  containerClassName={"!min-w-0"}
                  onChange={(e) => handleChange(e)}
                  value={profile?.email}
                  disable={isAddNewMember ? false : true}
                />
              </div>
            </>
            <div className="flex border-b  border-debatePalette-buttonBorder gap-2 items-center py-3">
              <label
                htmlFor="forum"
                className="min-w-20 text-sm text-debatePalette-title font-semibold"
              >
                Forum
              </label>
              <BasicSelect
                Options={isAddNewMember && (popupFor === "workspace" || popupFor === "management") ? forumOptionsForWorkspace : forumOptions}
                defaultValue={defaultSubworkspace}
                value={selectedForum || defaultSubworkspace}
                disabled={popupFor === "subworkspace" ? true : false}
                onChange={(e) => handleForumChange(e)}
                optionForDisable={"isAccept"}
              />
            </div>
            <div className="flex border-b  border-debatePalette-buttonBorder gap-2 items-center py-3">
              <label
                htmlFor="role"
                className="min-w-20 text-sm text-debatePalette-title font-semibold"
              >
                Role
              </label>
              <BasicSelect
                Options={roleOptions}
                defaultValue={defaultRole}
                value={
                  selectedRole ||
                  defaultRole || {
                    label: "Member",
                    value: "Member",
                  }
                }
                onChange={(e) => setSelectedRole(e)}
              />
            </div>
            <div className="flex border-b border-debatePalette-buttonBorder gap-2 items-center py-3">
              <label
                htmlFor="assigned"
                className="min-w-20 text-sm text-debatePalette-title font-semibold"
              >
                Assigned
              </label>
              <DatePicker
                inputName={"assigned"}
                startDate={isAddNewMember ? new Date() : profile?.assigned}
                handleChange={(date) => handleChange(date)}
                isDisable={true}
              />
            </div>
            <div className="flex justify-center  gap-2 items-center py-3">
              <BasicButton
                loading={
                  addUserToSubWorkspacePending || editUserToSubWorkspacePending
                }
                color={"primary"}
                className={"px-5 py-1.5 capitalize"}
                onClick={() =>
                  isAddNewMember ? handleAddUser() : handleEditUser()
                }
              >
                {isAddNewMember ? "Add" : "Save"}
              </BasicButton>
            </div>
          </CardBody>
        </Card>
      </DialogBody>

      <XMarkIcon
        className="w-6 h-6 absolute top-4 right-4 hover:text-debatePalette-title cursor-pointer"
        onClick={() => {
          handleClose();
          handleStateReset();
        }}
      />
    </Dialog>
  );
};

export default AddEditRole;
