import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Avatar, Button } from "@material-tailwind/react";
import React, { useRef, useState } from "react";
import UserAvatar from "../../../Assets/Svg/User.svg";
import ImageCropperSRP from "../../Functionality/ImageCropperSRP";
import ImageOrInitials from "../../Functionality/ImageOrInitials";

const UserAvtarUpload = ({ picture, setPicture, userName }) => {
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col">
      <ImageCropperSRP
        inputRef={inputRef}
        closeModal={() => setOpen(false)}
        modalOpen={open}
        updateAvatar={setPicture}
        setModalOpen={setOpen}
      />
      <div
        className={`relative flex flex-col max-w-40 max-h-40 cursor-pointer items-center gap-6 rounded-md group`}
      >
        {!picture && !userName ? (
          <Avatar
            variant="circular"
            size="lg"
            src={UserAvatar}
            className="w-32 h-32 group-hover:!opacity-20 shadow-md"
          />
        ) : (
          <>
            <ImageOrInitials
              imageSrc={picture}
              initials={userName}
              size={"h-32 w-32"}
              classNameInitials={"text-3xl xs:text-5xl group-hover:!opacity-25"}
              classNameImage={" group-hover:!opacity-20 shadow-md"}
            />
            {picture && (
              <div className="absolute inset-0 flex flex-col gap-2 justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <Button
                  variant="text"
                  color="blue"
                  className="px-3 py-2 hover:bg-secondary text-secondary hover:text-white"
                  onClick={() => {
                    if (inputRef.current) {
                      inputRef.current.click();
                    }
                  }}
                >
                  <PencilIcon className="w-6 h-6 !stroke-2   text-inherit" />
                </Button>
                <Button
                  variant="text"
                  color="red"
                  className="px-3 py-2 hover:bg-debatePalette-timer text-debatePalette-timer hover:text-white"
                  onClick={() => setPicture(null)}
                >
                  <TrashIcon className="w-6 h-6 !stroke-2   text-inherit" />
                </Button>
              </div>
            )}
          </>
        )}
        {!picture && (
          <div className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <Button
              variant="text"
              color="blue"
              className="px-3 py-2 hover:bg-secondary hover:bg-opacity-20 text-secondary hover:text-debatePalette-title"
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.click();
                }
              }}
            >
              {" "}
              <PlusIcon className="h-8 w-8 text-inherit" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAvtarUpload;
