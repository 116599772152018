import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";
import Container from "../../Components/UI/Container";
import Loader from "../../Components/UI/Loader";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useGetPrivateUserWiseDebatedList } from "../../Web-Hooks/Debate/use-debate";
import Posts from "../Posts/Posts";
import { useGetUserById } from "../../Web-Hooks/Profile/use-profile";
import { useWorkspace } from "../../Context/WorkspaceContext";

const Private = () => {
  const location = useLocation();
  const { pathname } = location;
  const opponentId = pathname.slice(1).split("/")[1];
  const { selectedSubWorkspace } = useSubWorkspace();
  const { user } = useAuth();
  const { selectedWorkspace } = useWorkspace();

  const { data: userDetails } = useGetUserById(
    user?.userId,
    selectedSubWorkspace?.subWorkSpaceId,
    selectedWorkspace?.workSpaceId
  );
  const { data: PrivateDebateList, isLoading: isPrivateDebateListLoading } =
    useGetPrivateUserWiseDebatedList(
      user?.userId,
      selectedSubWorkspace?.subWorkSpaceId,
      opponentId
    );

  return (
    <Container>
      {/* Title */}
      <Suspense fallback={<Loader />}>
        <Posts
          apiType={pathname}
          AllDebates={PrivateDebateList}
          user={userDetails}
          isLoading={isPrivateDebateListLoading}
        />
      </Suspense>
    </Container>
  );
};

export default Private;
