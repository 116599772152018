import { Button, Spinner } from "@material-tailwind/react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { ReactComponent as Copy } from "../../Assets/Svg/Copy.svg";
import { ReactComponent as Pencil } from "../../Assets/Svg/EditPencil.svg";
import { ReactComponent as Trash } from "../../Assets/Svg/Trash.svg";
import CitationComponent from "../../Components/Functionality/CitationComponent";
import NoData from "../../Components/Functionality/NoData";
import SortByFilter from "../../Components/Functionality/SortByFilter";
import AddCategoriesPopup from "../../Components/Popup/AddCategoriesPopup";
import CitationPopup from "../../Components/Popup/CitationPopup";
import InfoPopup from "../../Components/Popup/InfoPopup";
import ClickableChip from "../../Components/UI/Chip/ClickableChip";
import Container from "../../Components/UI/Container";
import SearchField from "../../Components/UI/Inputs/SearchField";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import useDebounce from "../../Services/useDebounce";
import { LibrarySortByOptions } from "../../Utils/Constant";
import { convertDateToUTC, copyToClipboard } from "../../Utils/Helper";
import {
  useAddUserCategory,
  useGetCategoriesByWorkspaceId,
} from "../../Web-Hooks/Categories/use-categories";
import {
  useCitationRemove,
  useGetCitationUserWise,
  useUpdateCitationUserWise,
  useUploadCitationUserWise,
} from "../../Web-Hooks/LibraryAPI/use-citation";
import LibraryCard from "./LibraryCard";

const Library = () => {
  const location = useLocation();
  const { search: searchParams } = location;
  const query = new URLSearchParams(searchParams);
  const sortByDefault = query.get("sortBy");
  const isFromDebate = query.get("isFromDebate");
  const isFromAddArgument = query.get("isFromAddArgument");
  const isFromDebatePhoto = query.get("isFromDebatePhoto");
  const [search, setSearch] = useState("");
  const deferredSearch = useDebounce(search, 500);
  const [isEdit, setIsEdit] = useState(false);
  const [selected, setSelected] = useState(null);
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isFromExternal, setIsFromExternal] = useState(false);
  const [selectedCitation, setSelectedCitation] = useState({
    citationUrl: "",
    citationNote: "",
    citationType: "",
    citationFile: null,
  });
  const [deletePopup, setDeletePopup] = useState(null);
  const [option, setOption] = useState(
    () =>
      LibrarySortByOptions.find((option) => option.value === sortByDefault) || {
        label: "Most Recent",
        value: "recent",
      }
  );
  const [citationPopup, setCitationPopup] = useState(false);
  const { user } = useAuth();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const { data: categoryList, isLoading } = useGetCategoriesByWorkspaceId(
    selectedWorkspace?.workSpaceId
  );
  const { mutateAsync: addCategory } = useAddUserCategory();

  const {
    mutateAsync: uploadCitationOnLibrary,
    isPending: isUploadCitationPending,
  } = useUploadCitationUserWise();
  const { mutateAsync: updateCitation, isPending: isUpdatingCitationPending } =
    useUpdateCitationUserWise();

  const { data: citationList, isLoading: isLoadingCitation } =
    useGetCitationUserWise(user?.userId, selected?.categoryName, deferredSearch, option?.value);

  const { mutateAsync: removeCitation, isPending: isCitationDeletePending } =
    useCitationRemove();

  const Actions = [
    {
      SVG: Copy,
      title: "Copy",
      handleClick: (citation) => {
        if (!isFromDebate && !isFromDebatePhoto && !isFromAddArgument) {
          copyToClipboard(citation?.refUrl);
          return;
        }
        setSelectedCitation({
          citationUrl: citation?.refUrl,
          citationNote: citation?.note,
          citationType: citation?.refFileType,
          citationSize: citation?.size
        });
        setCitationPopup(true);
      },
    },
    {
      SVG: Pencil,
      title: "Edit",
      isDisable: isFromDebate || isFromAddArgument,
      handleClick: (citation) => {
        setCitationPopup(true);
        setIsEdit(true);
        setSelectedCitation({
          citationUrl: citation?.refUrl,
          citationNote: citation?.note,
          citationType: citation?.refFileType,
          citationId: citation?.id,
        });
      },
    },
    // {
    //   SVG: Share,
    //   title: "Share",
    // },
    {
      SVG: Trash,
      title: "Delete",
      isDisable: isFromDebate || isFromAddArgument,
      handleClick: (citation) => {
        setDeletePopup(citation?.id);
      },
    },
  ];

  const handleAddCategory = async () => {
    const categoryData = {
      userId: user?.userId,
      UCCategoryName: title,
      UcThumbNail: "NA",
      SubWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
      WorkSpaceId: selectedWorkspace?.workSpaceId,
      CreatedAt: convertDateToUTC(new Date()),
      UpdatedAt: convertDateToUTC(new Date()),
    };
    await addCategory(categoryData);
    handleClose();
  };
  const handleClose = () => {
    setOpen(!open);
  };
  const handleSelect = (title) => {
    if (title?.categoryName === selected?.categoryName) {
      setSelected(null);
    } else {
      setSelected(title);
    }
  };

  const handleCitationSubmit = async (selectedCitation) => {
    // if (isFromDebate || isFromAddArgument || isFromDebatePhoto) {
    //   navigate(`${prevPathname}`, {
    //     replace: true,
    //     state: isFromDebatePhoto ? {LibraryPhoto: selectedCitation} : { LibraryCitation: selectedCitation },
    //   });
    //   setSelectedCitation({
    //     citationNote: "",
    //     citationType: "",
    //     citationUrl: "",
    //   });
    //   setCitationPopup(false);
    //   return;
    // }
    const { userId } = user || {};
    const { categoryName } = selected || {};
    const { citationNote, citationType, citationFile, citationUrl } =
      selectedCitation;
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("SubWorkSpaceId", selectedSubWorkspace?.subWorkSpaceId);
    formData.append("CreateAt", new Date().toISOString());
    formData.append("Category", categoryName || "");
    formData.append("Note", citationNote);
    formData.append("File", citationFile);
    formData.append("FileSize", citationFile?.size || 0);
    formData.append("FileType", citationType || "");
    if (citationType === "url") {
      formData.delete("File");
      formData.delete("FileSize");
      formData.append("RefUrl", citationUrl);
    }

    if (formData) {
      const data = await uploadCitationOnLibrary(formData);
      return data;
    }
  };
  const handleCitationUpdate = async () => {
    const { userId } = user || {};
    const { citationNote, citationId } = selectedCitation;
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("SubWorkSpaceId", selectedSubWorkspace?.subWorkSpaceId);
    formData.append("updatedAt", new Date().toISOString());
    formData.append("Note", citationNote);
    formData.append("id", citationId);

    if (formData) {
      await updateCitation(formData)
        .then((res) => {
          setSelectedCitation((prev) => ({
            ...prev,
            citationUrl: res.data,
          }));
          setSelectedCitation({
            citationNote: "",
            citationType: "",
            citationUrl: "",
            citationFile: null,
            citationId: "",
          });
          setCitationPopup(false);
        })
        .catch(() => {
          toast.error("Error uploading citation");
          setSelectedCitation({
            citationNote: "",
            citationType: "",
            citationUrl: "",
          });
          setCitationPopup(false);
        });
    }
  };

  const renderLoaderChip = () => {
    const numChips = 25; // Number of divs to render

    return Array.from({ length: numChips }).map((_, index) => (
      <div
        key={index}
        className="py-2 px-3 w-24 h-12 border items-center border-debatePalette-buttonBorder opacity-20 justify-center gap-2 flex rounded-md animate-pulse"
      >
        <div className="w-[60%] h-5 bg-debatePalette-hr opacity-20"></div>
        <div className="w-[20%] h-5 rounded-full bg-debatePalette-hr opacity-20"></div>
      </div>
    ));
  };

  const renderLoaderCitations = () => {
    const numChips = 4; // Number of divs to render

    return Array.from({ length: numChips }).map((_, index) => (
      <div
        key={index}
        className="flex gap-2 h-14 w-full items-center animate-pulse"
      >
        <figure className="w-14 h-14 bg-debatePalette-hr opacity-20"></figure>
        <div className="h-full p-4 flex items-center rounded-md flex-1 border bg-debatePalette-hr opacity-20"></div>
      </div>
    ));
  };

  // const AddCitationTag = [
  //   {
  //     categoryId: Date.now(),
  //     categoryName: "Add",
  //     thumbnail: PlusImage,
  //     count: 37,
  //     isUserCreated: false,
  //     workSpaceId: Date.now(),
  //   },
  // ];

  return (
    <Container>
      <section className="flex flex-col gap-0 w-full h-full">
        <div className="flex items-center gap-4 w-full mb-4 justify-between relative">
          <div className="w-full sm:w-3/4 lg:w-2/4">
            <SearchField
              id="library-search"
              name="library-search"
              search={search}
              setSearch={setSearch}
            />
          </div>
          <CitationComponent
            MenuHandlerComponent={
              <Button
                variant="filled"
                className=" text-debatePalette-title bg-primary"
              >
                Upload Media
              </Button>
            }
            isLoadingFromHandleSubmit={isUploadCitationPending}
            handleCitationSubmit={async (citation) =>
              await handleCitationSubmit(citation)
            }
            isImageType={false}
          />
        </div>

        <div className="flex justify-start gap-3 items-center flex-wrap">
          {isLoading && renderLoaderChip()}
          {!isLoading &&
            categoryList?.map((category, i) => (
              <ClickableChip
                key={category?.categoryId}
                selected={selected}
                title={category.categoryName}
                SVG={category.thumbnail}
                isSVG={false}
                handleClick={() => handleSelect(category)}
              />
            ))}
          {/* {AddCitationTag.map((category, i) => (
            <ClickableChip
              key={category?.categoryId}
              selected={selected}
              title={category.categoryName}
              SVG={category.thumbnail}
              isSVG={false}
              handleClick={() => setOpen(true)}
            />
          ))} */}
        </div>

        <div className="flex justify-between w-full h-20 border-b border-debatePalette-buttonBorder py-4">
          {selected ? (
            <ClickableChip
              selected={selected}
              title={selected.categoryName}
              SVG={selected.thumbnail}
              isSVG={false}
            />
          ) : (
            <div className="flex h-0"></div>
          )}
          <SortByFilter
            Options={LibrarySortByOptions}
            setOption={setOption}
            defaultValue={option}
          />
        </div>
        <div className={`flex mt-4 flex-col gap-2 w-full ${citationList?.length > 0 ? "h-full" : "max-h-64"} overflow-y-auto`}>
          {isLoadingCitation ? (
            renderLoaderCitations()
          ) : (
            <div className="flex flex-col gap-2">
              {citationList?.length > 0 ? (
                citationList?.map((citation, i) => (
                  <LibraryCard
                    Actions={Actions}
                    key={citation.id}
                    Citation={citation}
                  />
                ))
              ) : (
                <NoData message={"No citations found"}  size={"w-full h-48"} />
              )}
            </div>
          )}
        </div>
      </section>
      {citationPopup && isUploadCitationPending ? (
        <Spinner />
      ) : (
        <CitationPopup
          setSelectedCitation={setSelectedCitation}
          popupOpen={citationPopup}
          selectedCitation={selectedCitation}
          setPopupOpen={(pop) => {
            setCitationPopup(pop);
            setIsFromExternal(pop);
          }}
          handleSubmit={
            isEdit
              ? handleCitationUpdate
              : () => handleCitationSubmit(selectedCitation)
          }
          isFromExternalCitation={isFromExternal}
          isUploadCitationPending={
            isUploadCitationPending || isUpdatingCitationPending
          }
          isEdit={isEdit}
        />
      )}
      {open && (
        <AddCategoriesPopup
          handleClose={handleClose}
          open={open}
          title={title}
          setTitle={setTitle}
          handleSubmit={() => handleAddCategory()}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      )}
      {deletePopup && (
        <InfoPopup
          title={"Delete"}
          open={deletePopup ? true : false}
          description={"Are you sure you want to delete this citation"}
          btn1={"cancel"}
          btn2={"delete"}
          handleClose={() => setDeletePopup(null)}
          onClickBtn1={() => setDeletePopup(null)}
          onClickBtn2={() => {
            removeCitation({ id: deletePopup });
            setDeletePopup(null);
          }}
          loading2={isCitationDeletePending}
        />
      )}
    </Container>
  );
};

export default Library;
