import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { components } from "react-select";
import { ReactComponent as Google } from "../../Assets/Svg/Google.svg";
import { ReactComponent as SendInvitePlane } from "../../Assets/Svg/SendInvitePlane.svg";
import InputField from "../../Layout/InputField";
import TagsInput from "../Functionality/TagsInput";
import ActionButton from "../UI/Buttons/ActionButton";
import BasicButton from "../UI/Buttons/BasicButton";

import toast from "react-hot-toast";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { auth } from "../../Services/Google-Signin/config";
import useDebounce from "../../Services/useDebounce";
import { useAddSubWorkspace } from "../../Web-Hooks/Workspace/use-subworkspace";
import { useValidateSpaceName } from "../../Web-Hooks/use-validation";
import CSVUploader from "../Functionality/CSVUploader";
import ConatctList from "../Functionality/ConatctList";
import DownloadSampleCSV from "../../Utils/DownloadSampleCSV";

const AddSubWorkspacePopup = ({ handleClose, open }) => {
  const [contacts, setContacts] = useState([]);
  const [displayContacts, setDisplayContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [openMemberSelect, setOpenMemberSelect] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [subWorkFormData, setSubWorkFormData] = useState({
    SubWorkSpaceName: "",
    Emails: [],
  });
  const { user } = useAuth();
  const googleAccessToken = user?.googleToken;
  const { selectedWorkspace } = useWorkspace();
  const subWorkspaceName = useDebounce(subWorkFormData?.SubWorkSpaceName, 500);

  const {
    mutateAsync: addSubWorkspaceMutate,
    isPending: isAddingSubWorkspace,
  } = useAddSubWorkspace();
  const { data: isValidSubWorkSpaceName, isLoading: isValidatingSubWorkSpaceName } =
    useValidateSpaceName(
      "SubWork",
      subWorkspaceName || null,
      selectedWorkspace?.workSpaceId
    );
  const handleSubmit = async (workspaceMember) => {
    const newSubWorkspace = {
      ...subWorkFormData,
      Emails: workspaceMember,
      WorkSpaceId: selectedWorkspace?.workSpaceId,
      InvitedBy: user?.userId,
    };

    try {
      addSubWorkspaceMutate(newSubWorkspace)
        .then((res) => {
          toast.success("forum created successfully");
        })
        .catch((error) => {
          console.error("Error creating workspace or subworkspace:", error);
        })
        .finally(() => {
          cleanUpState();
        });

      // selectSubWorkspace(subWorkspace);
    } catch (error) {
      console.error("Error creating workspace or subworkspace:", error);
    }
  };
  const cleanUpState = () => {
    setSubWorkFormData({
      SubWorkSpaceName: "",
      Emails: [],
    });
    setSelectedContacts([]);
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (searchValue) {
        const filteredList = contacts.filter((item) =>
          item.label.toLowerCase().includes(searchValue.toLowerCase())
        );
        setDisplayContacts(filteredList);
      } else {
        setDisplayContacts(contacts);
      }
    }, 300); // Adjust the debounce delay (300ms) as needed

    return () => clearTimeout(debounceTimeout);
  }, [searchValue, contacts]);

  const requestContactAuthorization = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
      provider.addScope(
        "https://www.googleapis.com/auth/contacts.other.readonly"
      );

      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);

      if (credential) {
        const accessToken = credential.accessToken;
        fetchContacts(accessToken);
      }
    } catch (error) {
      console.error("Error requesting contact authorization:", error);
    }
  };

  const fetchContacts = async (token) => {
    try {
      const response = await fetch(
        "https://people.googleapis.com/v1/otherContacts?readMask=names,emailAddresses",
        {
          headers: {
            "GData-Version": "3.0",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch contacts");
      }

      const contactsData = await response.json();
      const data = contactsData.otherContacts;
      const updatedData = data.map((contact, index) => ({
        id: contact.emailAddresses[0].metadata.source.id,
        label: contact.emailAddresses[0].value,
        value: contact.emailAddresses[0].value,
      }));
      setContacts(updatedData);
      setDisplayContacts(updatedData);
      setOpenMemberSelect(true);
      setSearchValue("");
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const customStyles = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#E8F5FB",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#424242",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#424242",
      ":hover": {
        backgroundColor: "#c1e7dd",
        color: "#0a431f",
      },
    }),
  };
  const CustomMultiValue = (props) => {
    return (
      <components.MultiValue {...props} key={props.data.id}>
        <span
          style={{ display: "flex", alignItems: "center" }}
          className="gap-2 "
        >
          <SendInvitePlane className="h-4 w-4" />{" "}
          <components.MultiValueLabel {...props} children={props.children} />{" "}
          {/* Include the label */}
        </span>
      </components.MultiValue>
    );
  };

  const handleCreateWorkspace = () => {
    const subWorkspaceMember = selectedContacts.map(
      ({ label, value }) => value
    );

    const memberString = subWorkspaceMember.join(";");
    setSubWorkFormData({
      ...subWorkFormData,
      SubWorkspaceName: subWorkFormData?.SubWorkSpaceName,
      Email: memberString,
    });
    if (subWorkspaceMember.length === 0) {
      toast.error("Please select atleast one member", "error");
      return;
    }
    handleSubmit(memberString);
    handleClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Replace spaces with dashes only if the input name is "WorkSpaceName"
    const processedValue =
      name === "SubWorkSpaceName" ? value.replace(/\s+/g, "-") : value;

    // Update the state based on the input name
    setSubWorkFormData((prevData) => ({
      ...prevData,
      [name]: processedValue,
    }));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          cleanUpState();
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className=""
      >
        <DialogHeader id="alert-dialog-title" className="w-full">
          Create Forum
        </DialogHeader>
        {openMemberSelect ? (
          <DialogBody className="w-full">
            <div className="w-full md:w-4/5">
              <ConatctList
                displayContacts={displayContacts}
                setSearchValue={setSearchValue}
                setSelectedContactsFromProp={setSelectedContacts}
                setOpenMemberSelect={setOpenMemberSelect}
                searchValue={searchValue}
                selectedContactsFromProp={selectedContacts}
                contacts={contacts}
              />
            </div>
          </DialogBody>
        ) : (
          <DialogBody className="w-full">
            <div
              id="alert-dialog-description"
              className="flex flex-col gap-4 w-full md:w-4/5"
            >
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="SubWorkSpaceName"
                  className="block text-lg font-semibold text-debatePalette-title"
                >
                  What’s your forum name?
                </label>
                <InputField
                  isControlled={true}
                  inputName={"SubWorkSpaceName"}
                  type="text"
                  className="border-2 border-gray-500 p-2 mt-1 block w-full"
                  placeholder="e.g. Forum-2"
                  value={subWorkFormData?.SubWorkSpaceName}
                  onChange={handleChange}
                  icon={
                    isValidatingSubWorkSpaceName ? (
                      <Spinner />
                    ) : (
                      isValidSubWorkSpaceName && <CheckIcon className="font-bold" />
                    )
                  }
                  isError={
                    isValidatingSubWorkSpaceName
                      ? false
                      : !isValidSubWorkSpaceName && subWorkspaceName !== ""
                  }
                  errorMessage={
                    isValidatingSubWorkSpaceName
                      ? ""
                      : !isValidSubWorkSpaceName &&
                      subWorkspaceName !== "" &&
                        "Forum name already exists"
                  }
                />
              </div>
              <div className=" h-full items-start justify-center w-full">
                <div className="flex justify-between items-center w-full my-4">
                  <p className="block text-lg font-semibold text-debatePalette-title">
                    Add colleagues
                  </p>

                  <div className="flex self-end gap-3 justify-center">
                    <p className="capitalize font-body flex items-center text-md text-debatePalette-title font-semibold">
                      Upload Contact
                    </p>
                    {googleAccessToken && (
                      <ActionButton
                        onClick={requestContactAuthorization}
                        count={""}
                        SVG={Google}
                      />
                    )}
                    <CSVUploader
                      setSearchValue={setSearchValue}
                      setDisplayContacts={setDisplayContacts}
                      setContacts={setContacts}
                      setOpenMemberSelect={setOpenMemberSelect}
                      selectedContacts={selectedContacts}
                    />
                    <DownloadSampleCSV/>
                  </div>
                </div>

                <TagsInput
                  contacts={selectedContacts}
                  setContacts={setSelectedContacts}
                  selectClass={"w-full max-h-32 h-32 my-4"}
                  customComponent={CustomMultiValue}
                  customStyles={customStyles}
                />
                <div className="flex justify-between items-center w-full my-4">
                  <BasicButton
                    variant={"outlined"}
                    className={"bg-transparent "}
                    onClick={() => {}}
                  >
                    Copy Invitation Link
                  </BasicButton>
                  <Button
                    loading={isAddingSubWorkspace}
                    className={
                      "px-5 bg-primary text-debatePalette-title normal-case text-base py-2"
                    }
                    onClick={handleCreateWorkspace}
                    disabled={
                      !isValidSubWorkSpaceName || selectedContacts?.length === 0
                    }
                  >
                    Create
                  </Button>
                </div>
              </div>
            </div>
          </DialogBody>
        )}
        <XMarkIcon
          className="w-6 h-6 absolute top-4 right-4 hover:text-debatePalette-title cursor-pointer"
          onClick={() => {
            cleanUpState();
            handleClose();
          }}
        />
      </Dialog>
    </>
  );
};

export default AddSubWorkspacePopup;
