import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import React from "react";

const InfoPopup = ({
  handleClose,
  open,
  title,
  btn1,
  onClickBtn1,
  btn2,
  onClickBtn2,
  description,
  btn1Variant,
  btn2Variant,
  description2,
  loading1,
  loading2,
  descriptionStack = [],
}) => {
  const isBtnOutline =
    "border border-debatePalette-shadow bg-transparent text-debatePalette-shadow text-base capitalize px-4 py-2";
  const isBtnFilled =
    "bg-primary text-debatePalette-title text-base capitalize px-4 py-2";
  const isBtnGhost = "text-debatePalette-link normal-case  border-none text-base  px-4 py-2"
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className=" min-w-[0%] md:min-w-[0%] lg:min-w-[0%] 2xl:min-w-[0%] "
      size="md"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogHeader id="info-popup" className="w-full justify-center">
        {title || " "}
      </DialogHeader>
      <DialogBody className="w-full flex justify-center items-center text-center">
        <div
          className={`flex flex-col gap-4 p-4 text-wrap text-lg font-normal text-debatePalette-title rounded-md w-auto `}
        >
          {description}
          {descriptionStack.length > 0 &&
            descriptionStack?.map((item, index) => <p key={index} className="text-base">{item}</p>)}
          {description2 && <span className="font-normal">{description2}</span>}
        </div>
      </DialogBody>
      <DialogFooter className="gap-2  justify-center">
        <Button
          variant={btn1Variant === "outline" ? "outlined" : btn1Variant === "text" ? "text" : "filled"}
          loading={loading1}
          onClick={onClickBtn1}
          className={`${btn1Variant === "outline" ? isBtnOutline : btn1Variant === "text" ? isBtnGhost : isBtnFilled} focus:ring-0 focus:opacity-100 focus:shadow-md min-w-28 shadow-md`}
          ripple={true}
        >
          {btn1}
        </Button>
        <Button
          variant={btn2Variant === "outline" ? "outlined" : btn2Variant === "text" ? "text" : "filled"}
          loading={loading2}
          onClick={onClickBtn2}
          className={`${btn2Variant === "outline" ? isBtnOutline : btn2Variant === "text" ? isBtnGhost : isBtnFilled} focus:ring-0 focus:opacity-100 focus:shadow-md min-w-28 shadow-md`}
          ripple={true}
        >
          {btn2}
        </Button>
      </DialogFooter>

      <XMarkIcon
        className="w-6 h-6 absolute top-4 right-4 hover:text-debatePalette-title cursor-pointer"
        onClick={handleClose}
      />
    </Dialog>
  );
};

export default InfoPopup;
