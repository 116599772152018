import React, { forwardRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalendarIcon } from "../../../Assets/Svg/CalanderIcon.svg";

const DatePicker = ({startDate = new Date(), handleChange, inputName, isDisable=false}) => {
  const [selectedDate, setSelectedDate] = useState(startDate);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="w-full border p-1.5 rounded-md border-debatePalette-buttonBorder text-start relative"
      onClick={onClick}
      ref={ref}
    >
      {value}
      <CalendarIcon className="absolute top-1/2 -translate-y-1/2 right-2 h-4 w-4" />
    </button>
  ));

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleChange(date);
  };

  return (
    <ReactDatePicker
      id={inputName}
      name={inputName}
      enableTabLoop={false}
      selected={selectedDate}
      onChange={(date)=>handleDateChange(date)}
      customInput={<ExampleCustomInput  />}
      dateFormat={"dd MMM, yyyy"}
      className="flex-1"
      wrapperClassName="w-full"
      disabled={isDisable}
    />
  );
};

export default DatePicker;
