// CreateDebateContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const CreateDebateContext = createContext({
  debateRule: {
    Rounds: "1",
    TimeFrame: "1 day",
    MaxVotesToWin: "25",
  },
  citation: [],
  setCitation: () => {},
  setDebateRule: () => {},
  debateInfo: {
    MotionOrClaim: "",
    CreatorOpeningArgument: "",
    DebateImage: null,
    Fields: [],
    Type: "blitz",
    IsOtheruserAllow: false,
    SideForDebate: null,
    Round: 1,
    IsPublishWithoutOpponent: false,
    IsDebateSemiPublic: false,
    IsDebatePublic: true,
    IsDebatePrivate: false,
    NominateUserId: null,
    InvitedUserId: null,
    FileSize: 0,
    CustomRounds: null,
    CustomTimeFrame: null,
    CustomeMaxVotesToWin: null,
    ScalerPoint: 0,
  },
  handleStateUpdate: () => {},
  handleNext: () => {},
  handleBack: () => {},
  viewers: [],
  setViewers: () => {},
});

export const useCreateDebateContext = () => {
  return useContext(CreateDebateContext);
};

export const CreateDebateProvider = ({ children }) => {
  const [citation, setCitation] = useState([]);
  const [viewers, setViewers] = useState([]);
  const [query] = useSearchParams();
  const [debateInfo, setDebateInfo] = useState({
    MotionOrClaim: "",
    CreatorOpeningArgument: "",
    DebateImage: null,
    Fields: [],
    Type: "blitz",
    IsOtheruserAllow: false,
    SideForDebate: null,
    IsPublishWithoutOpponent: false,
    IsDebateSemiPublic: false,
    IsDebatePublic: true,
    IsDebatePrivate: false,
    NominateUserId: null,
    InvitedUserId: null,
    FileSize: 0,
    CustomRounds: null,
    CustomTimeFrame: null,
    CustomeMaxVotesToWin: null,
    ScalerPoint: 0,
    // IsDebateActive: false,
    // RedebateCount: 0,
    // IsRedebated: false,
  });
  const navigate = useNavigate();

    const handleStateUpdate = (updatedState) => {
    setDebateInfo(updatedState);
    localStorage.setItem("Debate", JSON.stringify(updatedState));
  };

  useEffect(() => {
    const updateStateOnRefresh = () => {
      const Debate = JSON.parse(localStorage.getItem("Debate")) || debateInfo;
      handleStateUpdate(Debate);
      const Citation = JSON.parse(localStorage.getItem("Citation")) || citation;
      setCitation(Citation);
      const Viewers = JSON.parse(localStorage.getItem("Viewers")) || viewers;
      setViewers(Viewers);
    };
    return updateStateOnRefresh(); // eslint-disable-next-line
  }, []);



  const handleNext = (page) => {
    navigate(`/create-debate/${page}?${query.toString()}`);
  };
  const handleBack = (page) => {
    navigate(`/create-debate/${page}?${query.toString()}`);
  };

  return (
    <CreateDebateContext.Provider
      value={{
        debateInfo,
        handleStateUpdate,
        handleNext,
        handleBack,
        setCitation,
        viewers,
        setViewers,
        citation,
      }}
    >
      {children}
    </CreateDebateContext.Provider>
  );
};
