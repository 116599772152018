// useArgumentAgent.js
import { useQuery } from "@tanstack/react-query";
import { checkCitationAgent, suggestCitationAgent } from "../../api/agents/agentsApi";

export const useCitationCheckAgent = (url,  debate_motion) => {
  // return useQuery({
  //   queryKey: ["citation-validate",url, debate_category, debate_motion, argument],
  //   queryFn: async () => {
  //     return await checkCitationAgent(url, debate_category, debate_motion, argument);
  //   },
  //   select: (data) => data.data,
  //   enabled:!!url|| !!debate_category || !!debate_motion || !!argument,
  // });
  return useQuery({
    queryKey: ["citation-validate", debate_motion,url],
    queryFn: async () => {
      return await checkCitationAgent(url, debate_motion);
    },
    select: (data) => data.data,
    enabled:!!url && !!debate_motion,
  });
};
export const useCitationSuggestAgent = (debate_argument) => {
  return useQuery({
    queryKey: ["citation-suggest", debate_argument],
    queryFn: async () => {
      return await suggestCitationAgent(debate_argument);
    },
    select: (data) => data.data,
    enabled:!!debate_argument,
  });
  // const queryClient = useQueryClient();

  // return useMutation({
  //   mutationFn: async ({ debate_argument }) => {
  //     return await suggestCitationAgent({debate_argument });
  //   },
  //   onSuccess: async () => {
  //     // Optionally invalidate queries or perform other actions on success
  //     // await queryClient.invalidateQueries({ queryKey: ["categories"] });
  //   },
  //   onError: (error) => {
  //     console.error("Error during mutation:", error.message);
  //   },
  // });
};
// const queryClient = useQueryClient();

// return useMutation({
//   mutationFn: async ({  url,debate_category,debate_motion,argument }) => {
//     return await checkCitationAgent({  url,debate_category,debate_motion,argument });
//   },
//   onSuccess: async () => {
//     // Optionally invalidate queries or perform other actions on success
//     // await queryClient.invalidateQueries({ queryKey: ["categories"] });
//   },
//   onError: (error) => {
//     console.error("Error during mutation:", error.message);
//   },
// });