import React from "react";
import BasicButton from "../UI/Buttons/BasicButton";

const DebateFooter = ({
  debateInfo,
  content,
  isNext = true,
  handleBack,
  handleNext,
  loading,
  btnName,
}) => {
  return (
    <div className="flex flex-col mt-8 sm:flex-row items-center gap-3 justify-between w-full">
      {/* <Typography>
        Step {content?.page} of{" "}
        {debateInfo?.Type === "custom"
          ? debateInfo?.IsDebatePrivate
            ? 6
            : debateInfo?.IsOtheruserAllow
            ? 4
            : 5
          : debateInfo?.IsDebatePrivate
          ? 5
          : debateInfo?.IsOtheruserAllow
          ? 3
          : 4}
      </Typography> */}
      {/* <div className="flex gap-3"> */}
      {content?.page !== 1 && (
        <BasicButton
          color={"primary"}
          className={`w-24 flex justify-center rounded-md `}
          onClick={handleBack}
        >
          Back
        </BasicButton>
      )}
      <BasicButton
        loading={loading}
        color={"primary"}
        isDisable={isNext}
        className={`w-24 flex justify-center rounded-md`}
        onClick={handleNext}
      >
        {btnName
          ? btnName
          : content?.page === (debateInfo?.Type === "custom" ? 5 : 4)
          ? "Publish"
          : "Next"}
      </BasicButton>
    </div>
    // </div>
  );
};

export default DebateFooter;
