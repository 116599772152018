import { Checkbox, Slider } from "@material-tailwind/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import DebateFooter from "../../Components/Functionality/DebateFooter";
import DebateHeader from "../../Components/Functionality/DebateHeader";
import Container from "../../Components/UI/Container";
import { useCreateDebateContext } from "../../Context/CreateDebateContext";
import { shadowBlue } from "../../Utils/Constant";

const RoundSelection = ({ noDebateFooter, setTerms }) => {
  const { debateInfo, handleStateUpdate, handleNext, handleBack } =
    useCreateDebateContext();
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);
  const content = {
    headLine: "Debate Rule",
    baseLine: "",
    page: 3,
    pageName: "RoundSelection",
  };

  const handlePublish = () => {
    localStorage.setItem("DebateInfo", JSON.stringify(debateInfo));
  };

  const rounds = [1, 3, 5, 7, 9];

  const duration = [
    {
      label: "1 Hour",
    },
    {
      label: "1 Day",
    },
    {
      label: "1 Week",
    },
  ];

  const handleNextButton = () => {
    handleNext("add-people");
    handlePublish();
  };
  return (
    <Container>
      <DebateHeader content={content} />
      <section className="w-full">
        <div className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4 flex flex-col gap-3">
          <p className="my-2 text-lg font-semibold">
            How many rounds do you want?
          </p>
          <div className="flex gap-2  md:gap-5">
            {rounds.map((round, i) => (
              <div
                key={round}
                onClick={() =>
                  handleStateUpdate({ ...debateInfo, CustomRounds: round })
                }
                className={`cursor-pointer flex items-center justify-center p-2 md:p-4 rounded-md border border-debatePalette-buttonBorder ${
                  round === debateInfo?.CustomRounds
                    ? `${shadowBlue} border-debatePalette-shadow`
                    : ""
                }`}
              >
                {round}
              </div>
            ))}
          </div>
          <p className="my-2 text-lg font-semibold">
            How long do you want each round?
          </p>
          <div className="flex gap-2  md:gap-5">
            {duration.map((dur, i) => (
              <div
                key={i}
                onClick={() =>
                  handleStateUpdate({
                    ...debateInfo,
                    CustomTimeFrame: dur.label,
                  })
                }
                className={` cursor-pointer flex items-center justify-center p-2 md:p-4 rounded-md border border-debatePalette-buttonBorder ${
                  dur.label === debateInfo?.CustomTimeFrame
                    ? `${shadowBlue} border-debatePalette-shadow`
                    : ""
                }`}
              >
                {dur.label}
              </div>
            ))}
          </div>

          <p className="my-2 text-lg font-semibold">
            How many votes to win round?
          </p>
          <div className="flex flex-col gap-2  md:gap-5">
            <Slider
              color="blue"
              value={debateInfo?.CustomeMaxVotesToWin}
              onChange={(e) =>
                handleStateUpdate({
                  ...debateInfo,
                  CustomeMaxVotesToWin: parseInt(e.target.value),
                })
              }
              thumbClassName="[&::-webkit-slider-thumb]:bg-debatePalette-shadow [&::-webkit-slider-thumb]:ring-debatePalette-shadow"
            />
            <div className="flex justify-between">
              <span className="font-semibold">
                {debateInfo?.CustomeMaxVotesToWin}
              </span>
              <span className="font-semibold">100</span>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <Checkbox
              color="blue"
              checked={isAcceptedTerms}
              onChange={() => {
                setIsAcceptedTerms(!isAcceptedTerms);
                setTerms(!isAcceptedTerms);
              }}
              ripple={false}
            />
            <p className="text-sm text-debatePalette-bodyText">
              I have read and agreed to the{" "}
              <Link
                to={process.env.REACT_APP_PRIVACY_POLICY}
                target="_blank"
                className="text-debatePalette-shadow underline"
              >
                Privacy Policy
              </Link>{" "}
              and{" "}
              <Link
                to={process.env.REACT_APP_TERMS_SERVICE}
                target="_blank"
                className="text-debatePalette-shadow underline"
              >
                Terms of use
              </Link>{" "}
              and{" "}
              <Link
                to={process.env.REACT_APP_EULA}
                target="_blank"
                className="text-debatePalette-shadow underline"
              >
                EULA
              </Link>{" "}
              community guidelines.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-between w-full ">
            {!noDebateFooter && (
              <DebateFooter
                debateInfo={debateInfo}
                content={content}
                handleNext={handleNextButton}
                handleBack={() => handleBack("add-visibility")}
                isNext={
                  debateInfo?.CustomeMaxVotesToWin &&
                  debateInfo?.CustomTimeFrame &&
                  debateInfo?.CustomRounds &&
                  isAcceptedTerms
                    ? false
                    : true
                }
              />
            )}
          </div>
        </div>
      </section>
    </Container>
  );
};

export default RoundSelection;
