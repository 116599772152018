import { Drawer } from "@material-tailwind/react";
import React, { useState } from "react";
import EditableProfileDetails from "./EditableProfileDetails";
import ProfileDetailsPage from "./ProfileDetails";

const ProfileDrawer = ({ openRight, closeDrawerRight }) => {
  const [isEdit, setIsEdit] = useState(false);

    const handleCloseDrawer = () => {
    setIsEdit(false);
    closeDrawerRight();
  };
  return (
    <Drawer
      placement="right"
      open={openRight}
      onClose={handleCloseDrawer}
      className={` sm:w-[500px] ${
        openRight ? "shadow-xl shadow-debatePalette-base" : ""
      } `}
      overlay={false}
      size={350}
    >
      {!isEdit ? (
        <ProfileDetailsPage
          closeDrawerRight={handleCloseDrawer}
          setIsEdit={setIsEdit}
        />
      ) : (
        <EditableProfileDetails
          closeDrawerRight={handleCloseDrawer}
          setIsEdit={setIsEdit}
        />
      )}
    </Drawer>
  );
};

export default ProfileDrawer;
