import React from "react";
import DebateFooter from "../../Components/Functionality/DebateFooter";
import DebateHeader from "../../Components/Functionality/DebateHeader";
import RadioCardBlock from "../../Components/Functionality/RadioCardBlock";
import { useCreateDebateContext } from "../../Context/CreateDebateContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import Container from "../../Components/UI/Container";

const AddVisibility = ({noDebateFooter}) => {
  const { debateInfo, handleStateUpdate, handleNext, handleBack } =
    useCreateDebateContext();
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();

  const content = {
    headLine: "Visibility",
    baseLine: "Office-party",
    page: 2,
    extraDetail: [
      {
        opt: `Public-anyone in ${selectedWorkspace?.workSpaceName}`,
        subOpt: "",
        value: "public",
      },
      {
        opt: `Semi-Public - anyone in ${selectedSubWorkspace?.subWorkSpaceName}`,
        subOpt: "",
        value: "semipublic",
      },
      {
        opt: "Private - Only specific people",
        subOpt: "Can only be viewed or joined by invitation",
        value: "private",
      },
    ],
    pageName: "DebatePreference",
  };
  const handleNextButton = () => {
    debateInfo?.IsDebatePrivate
      ? handleNext("add-plan")
      : debateInfo?.Type === "custom"
      ? handleNext("round-selection")
      : handleNext("add-people");
  };

  const handleState = (value) => {
    const IsDebateSemiPublic = value === "semipublic" ? true : false;
    const IsDebatePublic = value === "public" ? true : false;
    const IsDebatePrivate = value === "private" ? true : false;
    handleStateUpdate({
      ...debateInfo,
      IsDebateSemiPublic,
      IsDebatePublic,
      IsDebatePrivate,
    });
  };

  const Visibility = debateInfo?.IsDebatePublic
    ? "public"
    : debateInfo?.IsDebateSemiPublic
    ? "semipublic"
    : debateInfo?.IsDebatePrivate
    ? "private"
    : "";

  const selectedVisibility =
    debateInfo?.IsDebatePrivate ||
    debateInfo?.IsDebatePublic ||
    debateInfo?.IsDebateSemiPublic;
  return (
    <Container>
      <DebateHeader content={content} />
      <section className=" w-full">
        <div className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4 my-2">
          <RadioCardBlock
            content={content}
            status={Visibility}
            setStatus={(value) => handleState(value)}
          />
          {!noDebateFooter && <DebateFooter
            debateInfo={debateInfo}
            content={content}
            handleNext={handleNextButton}
            handleBack={() => handleBack("debate-type")}
            isNext={selectedVisibility ? false : true}
          />}
        </div>
        
      </section>
    </Container>
  );
};

export default AddVisibility;
