import {
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
} from "@material-tailwind/react";
import { Fragment, useState } from "react";
import { ReactComponent as Report } from "../../Assets/Svg/Report.svg";
import MenuListRender from "../../Components/UI/Menus/MenuListRender";
import { useAuth } from "../../Context/AuthContext";

function ThreeDotIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
      />
    </svg>
  );
}

export function ThreeDotAction({
  user,
  debateId,
  setConfirmPopup,
  setReportPopup,
  popupTitle,
  setPopupTitle,
  setUserId,
}) {
  const { user: loggedInUser } = useAuth();
  const [open, setOpen] = useState(false);
  const sameUser = user === loggedInUser?.userId;
  const PostThreeDotMenu = [
    // {
    //   SVG: FilledShare,
    //   item: `${debateId ? "Share Debate" : "Share Profile"}`,
    //   isHRline: false,
    //   value: "shareDebate",
    //   subOption: [
    //     {
    //       SVG: CopyLink,
    //       item: "Copy Link",
    //       isHRline: false,
    //       value: "copyLink",
    //       handleClick: (e) => handleClick(e, "share"),
    //     },
    //   ],
    // },
    {
      SVG: Report,
      item: "Report",
      isHRline: false,
      isRedText: true,
      value: "report",
      handleClick: (e) => handleClick(e, "report"),
      isLoggedInUser: user === loggedInUser?.userId,
    },
    // {
    //   SVG: Block,
    //   item: "Block User",
    //   isHRline: false,
    //   value: "blockUser",
    //   handleClick: (e) => handleClick(e, "blockUser"),
    //   isLoggedInUser: user === loggedInUser?.userId,
    // },
  ];

  const handleClick = (e, value) => {
    if (value === "shareProfile") {
      console.log("Share Profile");
    } else if (value === "report") {
      setReportPopup(true);
    } else if (value === "blockUser") {
      setUserId(user);
      setConfirmPopup(true);
      setPopupTitle("Block");
    }
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <Fragment>
      <Menu
        placement="bottom-end"
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
        open={open}
        handler={setOpen}
      >
        <MenuHandler>
          <IconButton
            variant="text"
            className={`${sameUser ? "hidden" : "block"} `}
          >
            <ThreeDotIcon />
          </IconButton>
        </MenuHandler>
        <MenuList className="!z-40">
          {PostThreeDotMenu.map((option, i) => (
            <MenuListRender
              ref={option.value}
              option={option}
              key={option.value}
            />
          ))}
        </MenuList>
      </Menu>
    </Fragment>
  );
}
