import { createContext, useContext, useEffect, useState } from "react";
import {
  useGetSubWorkspaceUserBySubId,
  useSubWorkspacesByWorkspaceId,
} from "../Web-Hooks/Workspace/use-subworkspace";
import { useAuth } from "./AuthContext";

const SubWorkspaceContext = createContext({
  subWorkspaceList: [],
  selectedSubWorkspace: null,
  selectSubWorkspace: () => {},
  selectWorkspaceId: () => {},
  SubWorkspaceUser: [],
  UpdateSubWorkSpace: () => {},
  SubWorkspaceUserLoading: false,
});

export const useSubWorkspace = () => {
  return useContext(SubWorkspaceContext);
};

export const SubWorkspaceProvider = ({ children }) => {
  const [selectedSubWorkspace, setSelectedSubWorkspace] = useState(null);
  const { data: SubWorkspaceUser, isLoading: SubWorkspaceUserLoading } =
    useGetSubWorkspaceUserBySubId(selectedSubWorkspace?.subWorkSpaceId);
  const [workSpaceId, setWorkSpaceId] = useState(() => {
    const localStorageValue = window.localStorage.getItem("selectedWorkspace");
    if (localStorageValue && localStorageValue !== "undefined") {
      return JSON.parse(localStorage.getItem("selectedWorkspace"))?.workSpaceId;
    }
    return null;
  });
  const {user: loggedInUser} = useAuth();
  const { data: subWorkspaceList, status } =
    useSubWorkspacesByWorkspaceId(workSpaceId, loggedInUser?.userId);

  useEffect(() => {
    const localStorageValue = window.localStorage.getItem(
      "selectedSubWorkspace"
    );
    if (localStorageValue && localStorageValue !== "undefined") {
      const selectedSubWorkspace = localStorage.getItem("selectedSubWorkspace")
        ? JSON.parse(localStorage.getItem("selectedSubWorkspace"))
        : subWorkspaceList[0];
      selectSubWorkspace(selectedSubWorkspace);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (status === "success") {
      if(workSpaceId && subWorkspaceList?.length) {
        selectSubWorkspace(subWorkspaceList[0]);
      }
    }
  }, [status, workSpaceId]);

  const selectWorkspaceId = (workspaceId) => {
    setWorkSpaceId(workspaceId);
  };
  const UpdateSubWorkSpace = (workspace) => {
    selectSubWorkspace(workspace);
  };
  const selectSubWorkspace = (subWorkspace) => {
    setSelectedSubWorkspace(subWorkspace);
    localStorage.setItem("selectedSubWorkspace", JSON.stringify(subWorkspace));
  };

  return (
    <SubWorkspaceContext.Provider
      value={{
        subWorkspaceList,
        selectedSubWorkspace,
        selectSubWorkspace,
        selectWorkspaceId,
        SubWorkspaceUser,
        UpdateSubWorkSpace,
        SubWorkspaceUserLoading,
      }}
    >
      {children}
    </SubWorkspaceContext.Provider>
  );
};
