// useArgumentAgent.js
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { debateSummary, suggestCategoryAgent, topicSuggestionAI } from "../../api/agents/agentsApi";

export const useTopicSuggestionAI = () => {
//   return useQuery({
//     queryKey: ["debate-topic-suggestion", debate_topic],
//     queryFn: async () => {
//       return await topicSuggestionAI(url, debate_topic);
//     },
//     select: (data) => data.data,
//     // enabled:!!url && !!debate_motion,
//   });
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (topic) => {
      return await topicSuggestionAI({
        debate_topic: topic,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["debate-topic-suggestion"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useCategorySuggestionAI = (debate_topic) => {
  return useQuery({
    queryKey: ["category-suggestionss", debate_topic],
    queryFn: async () => {
      return await suggestCategoryAgent(debate_topic);
    },
    select: (data) => data?.data,
    enabled:!!debate_topic,
  });
  // return useQuery({
  //   queryKey: ["debate-category-suggestion", debate_topic],
  //   queryFn: async () => {
  //     return await suggestCategoryAgent(debate_topic);
  //   },
  //   select: (data) => data.data,
  //   enabled:!!debate_topic
  // });

};
export const useDebateSummaryAI = (debateId) => {
  return useQuery({
    queryKey: ["debate-summary", debateId],
    queryFn: async () => {
      return await debateSummary(debateId);
    },
    select: (data) => data?.data["Debate Summary"],
    enabled:!!debateId,
  });
  // return useQuery({
  //   queryKey: ["debate-category-suggestion", debate_topic],
  //   queryFn: async () => {
  //     return await suggestCategoryAgent(debate_topic);
  //   },
  //   select: (data) => data.data,
  //   enabled:!!debate_topic
  // });

};
