import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
import { useState } from "react";
import { ReactComponent as EditPencil } from "../../../Assets/Svg/EditPencil.svg"

export function SideBarMenu({ label, options, handleClick, isArrow = true, setOpenPopup, isWorkSpaceEdit= false, isMobileLayout = false }) {
  const [toggle, setToggle] = useState();
  return (
    <Menu
      ripple={false}
      placement="bottom-start"
      open={toggle}
      handler={() => setToggle(!toggle)}
    >
      <MenuHandler>
        <div className="flex w-full justify-between">
        <Button
          ripple={false}
          variant="text"
          className="flex p-0 gap-2 w-full justify-center font-normal capitalize tracking-normal hover:bg-transparent active:bg-transparent"
        >
          <span className="flex justify-center text-xl text-debatePalette-background">
            {label}
          </span>
          {isArrow && (
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3.5 w-3.5 transition-transform mt-1 text-debatePalette-background ${
                toggle ? "rotate-180" : ""
              }`}
            />
          )}
        </Button>
        {
          isWorkSpaceEdit && (
            <EditPencil
              className="h-6 w-6 text-white cursor-pointer hover:text-debatePalette-title"
              onClick={() => setOpenPopup(true)}
            />
          )
        }
        </div>
      </MenuHandler>
      <MenuList>
        {options.map((option, index) => (
          <MenuItem
            className="flex gap-3 p-2 text-debatePalette-title font-medium"
            key={index}
            onClick={
              option?.route ? () => handleClick(option.route, option.state) : handleClick
            }
          >
            {option?.label}
            {!isMobileLayout && (
              <ChevronRightIcon className="h-4 w-4" />
            )}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
