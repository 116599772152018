import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import React from "react";

const CommonMenu = ({ menuHandler, options, heading, ListClassname, SvgClassName, position="bottom-end" }) => {
  return (
    <Menu placement={position} className="flex flex-col bg-white">
      <MenuHandler>{menuHandler}</MenuHandler>
      <MenuList className={`p-3 bg-secondary gap-2  z-75 ${ListClassname}`}>
        {heading && (
          <div className="flex items-center p-1">
            <p className="text-sm w-full text-center text-debatePalette-background">
              {heading}
            </p>
          </div>
        )}
        {options?.map((option) => (
          <MenuItem
            key={option?.id}
            className="flex items-center p-1 justify-start gap-3 min-w-40 z-0"
            onClick={()=>option?.handleClick(option?.value)}
          >
            {option.SVG && <option.SVG className={`h-4 w-4 ${SvgClassName}`} />}
            <p className={`text-md font-semibold text-inherit py-2 ${option?.isRedText && "!text-debatePalette-timer"}`}>
              {option?.label}
            </p>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default CommonMenu;
