import { CheckCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import React from "react";
import DocImage from "../../Assets/Images/doc.png";
import PDFImage from "../../Assets/Images/pdf.png";
import URLImage from "../../Assets/Images/url.png";
import Tooltip from "../../Components/UI/Tooltip/Tooltip";
import { useAuth } from "../../Context/AuthContext";
import { useFileViewer } from "../../Context/FileViewerContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { shadowBlue } from "../../Utils/Constant";
import { getLocalTime } from "../../Utils/Helper";
import { useRemoveCitation } from "../../Web-Hooks/Debate/use-debate";

const DebateCitationList = ({ citation, setCitation, argument }) => {
  const [citationValid] = React.useState({});
  const { mutateAsync: removeCitation } = useRemoveCitation();
  const { user: loggedInUser } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  const { handleFile } = useFileViewer();
  // Function to handle deletion of a citation
  const handleCitationDelete = (selectedCitation) => {
    const newCitation = citation.filter(
      (item) => item.citationUrl !== selectedCitation?.citationUrl
    );
    setCitation(newCitation);
    if (selectedCitation?.isCitationFromDatabase) {
      const citationToRemove = {
        CitationId: selectedCitation?.citationId,
        UserId: loggedInUser?.userId,
      };
      if (
        Object.values(citationToRemove).some(
          (item) => item === null || item === undefined || item === ""
        )
      ) {
        return;
      }
      removeCitation(citationToRemove);
    }
  };
  // const { data: CitationAssistance } = useCitationCheckAgent(
  //   debateInfo?.MotionOrClaim,
  //   citation[0]?.citationUrl
  // );

  // Function to determine if an item is valid
  const isCitationValid = (item) => {
    // Replace with your actual validation logic
    return citationValid[item.citationUrl] || false;
  };

  const CitationListRender = (item) => {
    const Src =
      item?.citationType === "doc"
        ? DocImage
        : item?.citationType === "pdf"
        ? PDFImage
        : item?.citationType === "url"
        ? URLImage
        : item?.citationUrl;

    const CitationTitle = item?.citationUrl?.split("_")[1]
      ? item?.citationUrl?.split("_")[1]
      : item?.citationUrl;

    return (
      <Tooltip
        position="bottom"
        key={item?.citationUrl}
        content={item?.citationNote}
        containerClass="!w-full"
        className="!w-full text-debatePalette-background bg-sideLayoutPrimary z-100"
      >
        <div
          className={`flex w-full cursor-pointer items-center px-3 py-2 gap-3 border rounded-md ${shadowBlue}`}
        >
          <img
            className="h-8 w-8 rounded-md object-cover object-center cursor-pointer"
            src={Src}
            alt={item?.citationType}
            onClick={(e) => {
              e.preventDefault(); // Prevent default download behavior
              if (item?.citationType === "url") {
                window.open(item?.citationUrl, "_blank");
                return;
              }
              handleFile({
                fileUrl: item?.citationUrl,
                fileType: item?.citationType,
                fileName: CitationTitle,
                fileUploadedAt: getLocalTime(item?.createdAt),
                createdBy: loggedInUser?.userName,
                creatorImage: loggedInUser?.userImage,
                channelName: selectedWorkspace?.workSpaceName,
              });
            }}
          />{" "}
          <p className="truncate w-[60%]">{CitationTitle}</p>
          <div className="flex flex-1 justify-end items-center gap-2">
            <TrashIcon
              className="h-6 w-6 text-debatePalette-timer opacity-50 hover:opacity-100"
              onClick={() => handleCitationDelete(item)}
            />
            {isCitationValid(item) && (
              <CheckCircleIcon color="green" className="h-7 w-7" />
            )}
          </div>
        </div>
      </Tooltip>
    );
  };

  return (
    <div className="flex flex-col py-2 gap-3 w-full">
      {citation?.map((item) => (
        <div className="flex flex-row gap-5 w-full" key={item?.citationUrl}>
          {CitationListRender(item)}
        </div>
      ))}
    </div>
  );
};

export default DebateCitationList;
