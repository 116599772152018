import React, { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import FeedHeader from "../Components/Functionality/FeedHeader";
import FilePopup from "../Components/Functionality/PDFViewer";
import AddUserDetailsPopup from "../Components/Popup/AddUserDetailsPopup";
import Version from "../Components/UI/Version";
import { useAuth } from "../Context/AuthContext";
import { useProfile } from "../Context/openProfileContext";
import { useSubWorkspace } from "../Context/SubWorkspaceContext";
import { useWorkspace } from "../Context/WorkspaceContext";
import GlobalAIAgent01 from "../Pages/GlobalAgent/GlobalAIAgent01";
import NotificationDrawer from "../Pages/Profile/NotificationDrawer";
import ProfileDrawer from "../Pages/Profile/ProfileDrawer";
import useDebounce from "../Services/useDebounce";
import { extractPathname } from "../Utils/Helper";
import { useGetUserById } from "../Web-Hooks/Profile/use-profile";
import Header from "./Header/Header";
import MobileLayout from "./MobileLayout";
import FeedSidebar from "./Sidebar/FeedSidebar";
import Sidebar from "./Sidebar/Sidebar";

const HomeLayout = () => {
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace: subWorkspace, SubWorkspaceUser } =
    useSubWorkspace();
  const [search, setSearch] = useState("");
  const location = useLocation();
  const { pathname, search: searchQuery } = location;
  const query = new URLSearchParams(searchQuery);
  const userName = query?.get("userName");
  const { openRight, setOpenRight } = useProfile();
  const [openNotification, setOpenNotification] = useState(false);
  const openDrawerNotification = () => setOpenNotification(true);
  const closeDrawerNotification = () => setOpenNotification(false);
  const openDrawerRight = () => {
    setOpenRight(true);
  };
  const closeDrawerRight = () => setOpenRight(false);
  const isTab = extractPathname(pathname) === "notification";
  const navigate = useNavigate();
  // const containerRef = useRef(null);
  const { user: loggedInUser } = useAuth();
  const {
    data: profileDetails,
    status,
    isFetching,
  } = useGetUserById(
    loggedInUser?.userId,
    subWorkspace?.subWorkSpaceId,
    selectedWorkspace?.workSpaceId
  );
  const [openAddUserDetails, setOpenAddUserDetails] = useState(false);

  const debounceSearch = useDebounce(search, 500);

  const handleSearch = useCallback(
    () => {
      if (debounceSearch.trim() === "") {
        pathname === "/search" &&
          navigate({ pathname: "/feed-public", search: "" });
      } else {
        pathname !== "/search"
          ? navigate(`/search?query=${encodeURIComponent(debounceSearch)}`)
          : navigate({
              pathname: pathname,
              search: `?query=${encodeURIComponent(debounceSearch)}`, // remove # from search query
            });
      }
    }, // eslint-disable-next-line
    [debounceSearch, navigate]
  );

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  useEffect(() => {
    // When the path changes, check if it's outside "create-debate" paths
    if (!location.pathname.startsWith("/create-debate")) {
      localStorage.removeItem("Debate");
      localStorage.removeItem("Citation");
      localStorage.removeItem("Viewers");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (status === "success") {
      setOpenAddUserDetails(profileDetails?.userName ? false : true);
    }
  }, [profileDetails, status]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       containerRef.current &&
  //       !containerRef.current.contains(event.target)
  //     ) {
  //       // Clicked outside the container, so set value to empty
  //       setSearch("");
  //     }
  //   };

  //   // Attach the event listener when component mounts
  //   window.addEventListener("click", handleClickOutside);

  //   // Detach the event listener when component unmounts
  //   return () => {
  //     window.removeEventListener("click", handleClickOutside);
  //   }; // eslint-disable-next-line
  // }, []);

  const excludedRoutes = [
    "/create-debate",
    "/library",
    "/role",
    "/notification",
    "/reference",
    "/debate/",
  ];

  const shouldShowGlobalAIAgent = !excludedRoutes.some((route) =>
    pathname.includes(route)
  );

  return (
    <div className="h-screen">
      <FilePopup />
      <Version />

      {shouldShowGlobalAIAgent && <GlobalAIAgent01 />}

      <Version />
      <div className=" h-full hidden md:block bg-sideLayoutPrimary">
        <header className="h-16 ">
          <Header
            setSearch={setSearch}
            search={search}
            openProfile={openDrawerRight}
            openDrawerNotification={openDrawerNotification}
          />{" "}
        </header>
        <section className="flex h-[calc(100vh-4rem)]">
          <section className="w-[72px] h-full bg-primary">
            <Sidebar openProfile={openDrawerRight} />
          </section>
          <section className="w-64 h-full bg-secondary rounded-l-md">
            <FeedSidebar user={selectedWorkspace} />
          </section>
          <section className="flex-1 bg-white relative">
            {/* {search && (
              <div
                className="border border-gray-200 left-0 p-8 rounded-md shadow-2xl w-full absolute top-0  bg-white z-50"
                ref={containerRef}
              >
                <div className="flex justify-start flex-wrap items-center gap-4">
                  {users.map((user, i) => (
                    <UserCard key={i} user={user} />
                  ))}
                  {users.length > 0 ? (
                    <ArrowRightCircleIcon
                      onClick={() => {
                        setSearch("");
                        navigate("/search");
                      }}
                      className="w-12 h-12  text-debatePalette-buttonBorder cursor-pointer"
                    />
                  ) : (
                    <div className="flex flex-col gap-5">
                      <p className="text-3xl font-bold">No user found</p>
                      <div
                        className="flex flex-row items-center  gap-2  cursor-pointer"
                        onClick={() => {
                          setSearch("");
                          navigate("/search");
                        }}
                      >
                        <span>View all users</span>
                        <ArrowRightCircleIcon className="w-5 h-5  text-debatePalette-buttonBorder cursor-pointer" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )} */}
            {extractPathname(pathname)[0] !== "createprofile" &&
              extractPathname(pathname)[0] !== "message" && (
                <div className="h-16">
                  <FeedHeader
                    apiType={pathname}
                    isTab={isTab}
                    AvtarStackCount={SubWorkspaceUser?.length}
                    userName={userName}
                  />
                </div>
              )}
            <div className="md:w-[calc(100vw-20.5rem)] h-[calc(100vh-8rem)] overflow-y-auto">
              <Outlet />
            </div>
          </section>
        </section>
      </div>
      <div className="h-full md:hidden">
        <MobileLayout
          search={search}
          setSearch={setSearch}
          selectedWorkspace={selectedWorkspace}
          isTab={isTab}
          openProfile={openDrawerRight}
          subWorkspace={subWorkspace}
          pathname={pathname}
          AvtarStackCount={SubWorkspaceUser?.length}
          userName={userName}
        >
          <Outlet />
        </MobileLayout>
      </div>
      <ProfileDrawer
        openRight={openRight}
        closeDrawerRight={closeDrawerRight}
      />
      <NotificationDrawer
        openNotification={openNotification}
        closeOpenNotification={closeDrawerNotification}
      />
      <AddUserDetailsPopup
        isFetching={isFetching}
        open={openAddUserDetails}
        user={profileDetails}
        handleClose={() => setOpenAddUserDetails(false)}
      />
    </div>
  );
};

export default HomeLayout;
