import {
  Avatar,
  Button,
  Chip,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../Assets/Images/DB_AI.png";
import Versus from "../../Assets/Images/versus.png";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import NoData from "../../Components/Functionality/NoData";
import Container from "../../Components/UI/Container";
import { useAuth } from "../../Context/AuthContext";
import { useDebateSummaryAI } from "../../Web-Hooks/Agents/use-global-agents";
import {
  useGetDebateResult
} from "../../Web-Hooks/Debate/use-debate";

const formatKeyName = (key) => {
  return key
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};

// Helper function to parse and format text
const FormatObj = (data) => {
  return (
    <div>
      {Object.entries(data).map(([key, value], index) => (
        <div key={index} className="mb-4">
          <strong>{formatKeyName(key)}:</strong>{" "}
          {typeof value === "object" ? (
            // If value is an object (like Participants), map through the nested object
            <span>
              {Object.entries(value).map(([subKey, subValue], subIndex) => (
                <div key={subIndex}>
                  <strong>{formatKeyName(subKey)}:</strong> {subValue}
                </div>
              ))}
            </span>
          ) : (
            <span>{value}</span>
          )}
        </div>
      ))}
    </div>
  );
};

// User detail component for rendering individual user information
const UserDetail = ({
  userName,
  userVote,
  voteStatus,
  avatarSrc,
  direction,
}) => {
  const isRight = direction === "right";

  return (
    <div
      className={`userDetail flex w-full sm:w-3/4 lg:w-full -space-x-16 lg:-space-x-10 xl:-space-x-16`}
    >
      {isRight && (
        <ImageOrInitials
          imageSrc={avatarSrc}
          initials={userName}
          size={"h-24 w-24 xs:w-32 xs:h-32"}
          classNameInitials={"text-3xl xs:text-5xl ring-4 ring-primary"}
          classNameImage={"ring-4 ring-primary"}
        />
      )}
      <div
        className={`flex-1 h-24 xs:h-32 flex flex-col justify-evenly items-center ${
          isRight ? "lg:ps-5 xl:ps-0" : "lg:pe-5 xl:pe-0"
        } bg-secondary rounded-${isRight ? "r" : "l"}-lg animate-${
          isRight ? "slideInLeft" : "slideInRight"
        }`}
      >
        <p className="text-debatePalette-background font-semibold text-xl xs:text-2xl">
          {userName}
        </p>
        <p className="text-debatePalette-background font-semibold text-base xs:text-lg">
          {userVote}
        </p>
        <Chip
          value={voteStatus}
          color={voteStatus === "won" ? "blue" : "red"}
        />
      </div>
      {!isRight && (
        <ImageOrInitials
          imageSrc={avatarSrc}
          initials={userName}
          size={"h-24 w-24 xs:w-32 xs:h-32"}
          classNameInitials={"text-3xl xs:text-5xl ring-4 ring-primary"}
          classNameImage={"ring-4 ring-primary"}
        />
      )}
    </div>
  );
};

// Main component to display active debate results
const ActiveDebateResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const debateId = pathname.split("/").pop();
  // const query = new URLSearchParams(searchParams);
  const [showSummary, setShowSummary] = React.useState(false);

  const { user: loggedInUser } = useAuth();
  const { data: debateResults } = useGetDebateResult(debateId);
  const { data: debateSummary, isLoading: isSummaryPending } =
    useDebateSummaryAI(debateId);
  // const { data: debateDetails } = useGetDebateDetails(debateId, loggedInUser?.userId);

  const handleNextRoundClick = () => {
    navigate(
      `/debate/${debateId}/add-argument?roundNo=${
        debateResults?.nextRound
      }&isNextRound=true`
    );
  };

  if (!debateResults?.length) {
    return <NoData message="Result not found" size="w-full h-[80%]" />;
  }

  return (
    <Container>
      {/* <SegmentedControl
        currentRound={
          debateDetails?.round ? JSON.parse(debateDetails?.round) : 0
        }
        totalRounds={3}
      /> */}
      {debateResults?.map((debate) => (
        <div key={debate?.roundNo} className="w-full">
          <div className="flex flex-row gap-2 items-start justify-center w-full mb-8">
            <Typography variant="h1" className="text-center w-full mb-8">
              Round {debate?.roundNo}
            </Typography>
            <Tooltip
              content="Show Debate Summary"
              className="bg-white text-black border border-black"
            >
              <button
                onClick={() => setShowSummary(!showSummary)}
                className="animate-blinker"
              >
                <img src={Logo} alt="Logo" height="20" width="20" />
              </button>
            </Tooltip>
          </div>
          <section className="flex flex-col lg:flex-row justify-center items-center gap-8 lg:gap-4 xl:gap-10 w-full">
            <UserDetail
              userName={debate?.creatorName}
              userVote={debate?.creatorVote}
              voteStatus={
                debate?.creatorUserId === debate?.winnerId ? "won" : "lost"
              }
              avatarSrc={debate?.creatorImage}
              direction="right"
            />
            <Avatar src={Versus} alt="avatar" className="h-20 w-20" />
            <UserDetail
              userName={debate?.opponentName}
              userVote={debate?.opponentVote}
              voteStatus={
                debate?.opponentUserId === debate?.winnerId ? "won" : "lost"
              }
              avatarSrc={debate?.opponentImage}
              direction="left"
            />
          </section>
          {debate?.totalRound > debate?.roundNo && (
            <section className="flex flex-col justify-center items-center gap-4 w-full mt-8">
              <Typography variant="h3" className="text-center">
                To Start New Round
              </Typography>
              <Button
                disabled={debate?.creatorUserId === loggedInUser?.userId}
                variant="filled"
                className="bg-primary text-debatePalette-title w-fit"
                onClick={handleNextRoundClick}
              >
                Next Round
              </Button>
            </section>
          )}
        </div>
      ))}
      {showSummary && !isSummaryPending && debateSummary && (
        <div className="mt-5">{FormatObj(debateSummary)}</div>
      )}
    </Container>
  );
};

export default ActiveDebateResults;
