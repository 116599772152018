import { Button, Tooltip } from "@material-tailwind/react";
import React, { forwardRef } from "react";
import classNames from 'classnames';

const BasicButton = forwardRef(
  (
    {
      children,
      className,
      size,
      variant,
      onClick,
      istooltip,
      toolcontent,
      isDisable,
      color,
      loading // New color prop for specifying the color theme
    },
    ref
  ) => {
    // Default value assignment outside of object destructuring
    istooltip = istooltip === undefined ? false : istooltip;

    // Define the color classes based on the color prop
    const colorClasses = {
      'primary': 'bg-primary text-black border-primary',
      'secondary': 'bg-secondary text-white border-secondary',
      'success': 'bg-green-500 text-white border-green-500',
      'error': 'bg-red-500 text-red-500 border-red-500',
      'gray': 'bg-debatePalette-bodyText text-debatePalette-bodyText border-debatePalette-bodyText',
      // Add more color themes as needed
    };

    const buttonClass = classNames('px-3 flex gap-1', {
      // Outlined variant
      'border': variant === 'outlined',
      // Apply border and text color for outlined variant based on color prop
      [colorClasses[color]?.split(' ').filter(cls => cls.includes('border-')).join(' ')]: variant === 'outlined',
      [colorClasses[color]?.split(' ').filter(cls => cls.includes('text-')).join(' ')]: variant === 'outlined',
      // Ghost variant
      'bg-transparent text-black shadow-none hover:shadow-none': variant === 'ghost',
      // Default variant (apply color classes)
      [colorClasses[color]?.split(' ').filter(cls => cls.includes('bg-') || cls.includes('text-')).join(' ')]: variant !== 'outlined' && variant !== 'ghost',
    });

    return (
      <Tooltip
        content={toolcontent}
        className={!istooltip ? "hidden" : ""}
      >
        <Button
          loading={loading}
          disabled={isDisable}
          size={size}
          variant={variant}
          className={`${buttonClass} ${className}`}
          onClick={onClick}
          ref={ref}
        >
          {children}
        </Button>
      </Tooltip>
    );
  }
);

export default BasicButton;
