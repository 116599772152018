import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  AddCitation,
  AddDebateToSubWorkspace,
  AddNextRoundArgument,
  AddNomination,
  AddOpponentArgument,
  AddOtherUserArgument,
  ConvertToOpenDebate,
  getDebateCitationList,
  getDebateDetails,
  getDebateDetailsPage,
  getDebateResult,
  GetDebateTypeDetails,
  getUserArgumentList,
  NominateNewUserToDebate,
  RemoveCitation,
  UpdateRedebateCount,
} from "../../api/debate/debate";
import {
  getPrivateDebateList,
  getPublicDebateList,
  getPublicUserWiseDebatedList,
  getPublicUserWiseVotedList,
  getSemiPublicDebateList,
  getSemiPublicUserWiseDebatedList,
  getSemiPublicUserWiseVotedList,
} from "../../api/debate/get_debate";
import { FileUploadAzure } from "../../api/library/library";

export const useGetDebateTypeDetails = () => {
  return useQuery({
    queryKey: ["debate-details"],
    queryFn: async () => {
      return await GetDebateTypeDetails();
    },
    select: (data) => data,
  });
};

export const useGetDebateDetails = (debateId,userId, status) => {
  return useQuery({
    queryKey: ["debate-details", debateId,userId, status],
    queryFn: async () => {
      return await getDebateDetails(debateId, userId);
    },
    select: (data) => data,
    enabled: !!userId && !!debateId,
  });
};
export const useGetDebateDetailsPage = (debateId, round, sortBy, userId) => {
  return useQuery({
    queryKey: ["debate-details-page", debateId, round, sortBy, userId],
    queryFn: async () => {
      return await getDebateDetailsPage(debateId, round, sortBy, userId);
    },
    select: (data) => data,
    enabled: !!debateId || !!userId || !!sortBy || !!round,
  });
};
export const useGetDebateResult = (debateId) => {
  return useQuery({
    queryKey: ["resultByDebateId", debateId],
    queryFn: async () => {
      return await getDebateResult(debateId);
    },
    select: (data) => data.roundResults,
    enabled: !!debateId,
  });
};

export const useGetPublicDebateList = (userId, workSpaceId) => {
  return useQuery({
    queryKey: ["public-feed-workspace", userId, workSpaceId],
    queryFn: async () => {
      return await getPublicDebateList(userId, workSpaceId);
    },
    select: (data) => data.debateList,
    enabled: !!userId || !!workSpaceId,
  });
};

export const useGetPublicUserWiseDebatedList = (
  userId,
  workSpaceId,
  opponentId
) => {
  return useQuery({
    queryKey: ["public-debated-workspace", userId, workSpaceId, opponentId],
    queryFn: async () => {
      return await getPublicUserWiseDebatedList(
        userId,
        workSpaceId,
        opponentId
      );
    },
    select: (data) => data.debateList,
    enabled: !!userId || !!workSpaceId || !!opponentId,
  });
};

export const useGetPublicUserWiseVotedList = (
  userId,
  workSpaceId,
  opponentId
) => {
  return useQuery({
    queryKey: ["public-voted-workspace", userId, workSpaceId, opponentId],
    queryFn: async () => {
      return await getPublicUserWiseVotedList(userId, workSpaceId, opponentId);
    },
    select: (data) => data.votedDebateList,
    enabled: !!userId || !!workSpaceId || !!opponentId,
  });
};

export const useGetSemiPublicDebateList = (userId, subWorkSpaceId) => {
  return useQuery({
    queryKey: ["feed-subworkspace", userId, subWorkSpaceId],
    queryFn: async () => {
      return await getSemiPublicDebateList(userId, subWorkSpaceId);
    },
    select: (data) => data.debateList,
    enabled: !!userId || !!subWorkSpaceId,
  });
};

export const useGetSemiPublicUserWiseVotedList = (
  userId,
  subWorkSpaceId,
  opponentId
) => {
  return useQuery({
    queryKey: ["voted-subworkspace", userId, subWorkSpaceId, opponentId],
    queryFn: async () => {
      return await getSemiPublicUserWiseVotedList(
        userId,
        subWorkSpaceId,
        opponentId
      );
    },
    select: (data) => data.votedDebateList,
    enabled: !!userId || !!subWorkSpaceId || !!opponentId,
  });
};

export const useGetSemiPublicUserWiseDebatedList = (
  userId,
  subWorkSpaceId,
  opponentId
) => {
  return useQuery({
    queryKey: ["debated-subworkspace", userId, subWorkSpaceId, opponentId],
    queryFn: async () => {
      return await getSemiPublicUserWiseDebatedList(
        userId,
        subWorkSpaceId,
        opponentId
      );
    },
    select: (data) => data.debateList,
    enabled: !!userId || !!subWorkSpaceId || !!opponentId,
  });
};

export const useGetPrivateUserWiseDebatedList = (
  userId,
  workSpaceId,
  opponentId
) => {
  return useQuery({
    queryKey: ["private-subworkspace", userId, workSpaceId, opponentId],
    queryFn: async () => {
      return await getPrivateDebateList(userId, workSpaceId, opponentId);
    },
    select: (data) => data.privateDebateLists,
    enabled: !!userId || !!workSpaceId || !!opponentId,
  });
};

export const useAddDebateToSubWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await AddDebateToSubWorkspace(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-debated-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debated-subworkspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debate-details"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useConvertToOpenDebate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await ConvertToOpenDebate(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["public-feed-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["feed-subworkspace"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useNominateNewUserToDebate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await NominateNewUserToDebate(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-debated-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debated-subworkspace"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useAddOpponentArgument = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await AddOpponentArgument(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-debated-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debated-subworkspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["user"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useAddOtherUserArgument = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await AddOtherUserArgument(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-debated-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debated-subworkspace"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useAddNextRoundArgument = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await AddNextRoundArgument(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-debated-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debated-subworkspace"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useUpdateRedebateCount = () => {
  return useMutation({
    mutationFn: async (Debate) => {
      return await UpdateRedebateCount(Debate);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useRemoveCitation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Citation) => {
      return await RemoveCitation(Citation);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["debate-citations"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useAddCitation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Citation) => {
      return await AddCitation(Citation);
    },
    onSuccess: async () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["debate-details-page"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["private-subworkspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debated-subworkspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["voted-subworkspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["feed-subworkspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-voted-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-debated-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-feed-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debate-citations"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debate-arguments"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["motion-citations"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useAddNomination = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Nomination) => {
      return await AddNomination(Nomination);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["debate-details-page"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useUploadBlob = () => {
  return useMutation({
    mutationFn: async (file) => {
      return await FileUploadAzure(file);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useGetDebateCitationList = (debateId) => {
  return useQuery({
    queryKey: ["debate-citations", debateId],
    queryFn: async () => {
      return await getDebateCitationList(debateId);
    },
    select: (data) => data.listOfCitation,
    enabled: !!debateId,
  });
};
export const useGetUserDebateArgumentList = (userId, debateId, type) => {
  return useQuery({
    queryKey: ["debate-arguments", userId, debateId],
    queryFn: async () => {
      return await getUserArgumentList(userId, debateId);
    },
    select: (data) => data.arguments,
    enabled: !!userId && !!debateId && type,
  });
};
