import { Avatar } from "@material-tailwind/react";
import React from "react";
import { ReactComponent as DoubleChecked } from "../../Assets/Svg/DoubleChecked.svg";
import { isTodayOrYesterday } from "../../Utils/Helper";
import TitleDivider from "../UI/TitleDivider";
import NoData from "./NoData";

const MessageScreen = ({ messageContent, user }) => {
  return (
    <div className="h-full w-full overflow-y-auto scrollbar-hide">
      <section className="p-6 flex flex-col justify-end h-full">
        {messageContent?.length > 0 ? (
          messageContent.map((message, index) => (
            <React.Fragment key={message.id}>
              <TitleDivider title={isTodayOrYesterday(message?.date)} />

              {message?.messageDetails.map((message, index) => (
                <div
                  className={`message-container ${
                    message.sender === "user" ? "justify-end" : "justify-start"
                  }`}
                  key={message.messageId}
                >
                  {message.sender !== "user" && (
                    <div className=" mr-4">
                      <Avatar
                        src="https://docs.material-tailwind.com/img/face-2.jpg"
                        alt="avatar"
                        size="sm"
                      />
                    </div>
                  )}
                  <div
                    className={`message-bubble	  ${
                      message.sender === "user" ? "sent" : "received"
                    }`}
                  >
                    {message.text}{" "}
                    <sub className="flex flex-row ml-2 gap-1 justify-end items-center pt-2">
                      <span className="  " style={{ fontSize: "12px" }}>
                        {message.messageTime}
                      </span>
                      {message.status === "read" && (
                        <DoubleChecked className=" w-4 h-4 " />
                      )}
                    </sub>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))
        ) : (
          <React.Fragment>
            <TitleDivider title={isTodayOrYesterday(new Date())} />
            <div className="my-8">
              <NoData message="No messages yet" />
              {/* <div className=" mr-4  flex gap-4 items-end">
                <Avatar
                  src="https://docs.material-tailwind.com/img/face-2.jpg"
                  alt="avatar"
                  size="lg"
                />
                <div className="flex flex-col items-start justify-center ">
                  <p className="text-4xl font-semibold capitalize">
                    {extractPathname(user)[1]}
                  </p>
                  <p className="text-xl text-debatePalette-bodyText font-medium ">
                Start conversation saying hi to{" "}
                <span className="capitalize">{extractPathname(user)[1]}</span>
              </p>
                </div>
              </div> */}

            </div>
          </React.Fragment>
        )}
      </section>
    </div>
  );
};

export default MessageScreen;
