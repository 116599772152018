import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getAllWorkspaceListNotificationStatus,
  getNominationByUserAndSubID,
  getNotificationByUser,
  updateAcceptDeclineNominationStatus,
  updateNotificationStatus,
  updateWorkspaceNotificationStatus,
} from "../../api/notification/notification";

export const useGetNotificationByUserId = (userId) =>
  useQuery({
    queryKey: ["notification", userId],
    queryFn: () => getNotificationByUser(userId),
    select: (data) => data,
    enabled: !!userId,
  });

export const useGetNominationByUserIdAndSubId = (userId, type) =>
  useQuery({
    queryKey: ["nomination", userId, type],
    queryFn: () => getNominationByUserAndSubID(userId, type),
    select: (data) => data.listOfNominations,
    enabled: !!userId || !!type,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

export const useGetNotificationStatusWorkspaceWise = (userId) =>
  useQuery({
    queryKey: ["workspace-status", userId],
    queryFn: () => getAllWorkspaceListNotificationStatus(userId),
    select: (data) => data.wrokSpaceToggles,
    enabled: !!userId,
  });
export const useUpdateNominationStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (nominationObj) => {
      return await updateAcceptDeclineNominationStatus(nominationObj);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["nomination"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
    
  });
};
export const useUpdateNotificationStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (statusObj) => {
      return await updateNotificationStatus(statusObj);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["notification"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
    
  });
};

export const useUpdateNotificationList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (workspaceStatusObj) => {
      return await updateWorkspaceNotificationStatus(workspaceStatusObj);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["workspace-status"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["nomination"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
