import React from "react";
import { InputFocusBlue, shadowNormal } from "../../../Utils/Constant";

const DebateTextArea = ({
  maxLength,
  placeholder,
  label,
  value = "",
  handleChange,
  onFocus,
  onBlur,
  className,
  readOnly= false
}) => {
  const wordCount = (value) => {
    // Split by spaces and newlines, and filter out empty strings
    return value?.trim()?.split(/\s+/)?.filter(Boolean)?.length;
  };

  const count = wordCount(value);
  return (
    <div className="relative flex flex-col gap-2  my-2 w-full">
      <label htmlFor={label} className="text-lg font-semibold">
        {label}
      </label>
      <textarea
        id={label}
        className={`${className} flex-1 flex relative resize-none border-2 align-middle border-debatePalette-buttonBorder focus:outline-none rounded-md p-2  ${shadowNormal} focus:border-debatePalette-shadow ${InputFocusBlue}`}
        rows={3}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
      />
      <span className="absolute bottom-0 right-3">{`${
        maxLength - (value ? count : 0)
      }`}</span>
    </div>
  );
};
export default DebateTextArea;
