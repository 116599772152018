import {
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import React from "react";
export default function CardPayment() {
  const stripe = useStripe();
  const elements = useElements();
  const paymentElementOptions = {
    layout: 'tabs',
    paymentMethodOrder: ['card']
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // Use stripe APIs to handle payment
  };
  return (
    <form onSubmit={handleSubmit} className="mt-8">
      <PaymentElement  id="payment-element"
          options={paymentElementOptions}/>
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
}
