import {
  ARGUMENT_ASSISTANCE,
  CATEGORIES_ASSISTANCE,
  CATEGORIES_SUGGESTION_AGENT,
  CITATION_SUGGESTION_AGENT,
  CITATION_VALIDATOR_AGENT,
  DEBATE_SUMMARIZATION,
  GLOBAL_TOPIC_SUGGESTIONS,
  MOTION_ASSISTANCE,
} from "../apiRoutes";
import AXIOS_INSTANCE_AGENT from "../axiosInstanceAgent";

export const topicSuggestionAI = async (debate_topic) => {
  // try {
  //   const requestBody = { debate_topic};

  //   const data = await AXIOS_INSTANCE_AGENT.post(
  //     GLOBAL_TOPIC_SUGGESTIONS,
  //     requestBody
  //   );
  //   return data;
  // } catch (error) {
  //   throw new Error("Failed to fetch private debate list");
  // }
  try {
    const { data } = await AXIOS_INSTANCE_AGENT.post(`${GLOBAL_TOPIC_SUGGESTIONS}`, debate_topic);
    return data;
  } catch (error) {
    throw new Error("Failed to fetch hot topics");
  }
}
// Add category for user
export const checkArgumentAgent = async (motion, category) => {
  try {
    const requestBody = { motion, category };

    const data = await AXIOS_INSTANCE_AGENT.post(
      ARGUMENT_ASSISTANCE,
      requestBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch private debate list");
  }
  // try {
  //   // Prepare the request body with the two parameters
  //   const requestBody = { motion, category };

  //   // Make the POST request with the requestBody
  //   const { data } = await AXIOS_INSTANCE_AGENT.post(
  //     ARGUMENT_ASSISTANCE,
  //     requestBody
  //   );
  //   return data;
  // } catch (error) {
  //   // Provide more details about the error for debugging
  //   const errorMessage =
  //     error.response?.data?.message ||
  //     "An error occurred while processing your request";
  //   throw new Error(errorMessage);
  // }
};
export const checkCategoryAgent = async (motion, category, img_url) => {
  // try {
  //   // Prepare the request body with the two parameters

  //   // Make the POST request with the requestBody
  //   const { data } = await AXIOS_INSTANCE_AGENT.post();
  //   return data;
  // } catch (error) {
  //   // Provide more details about the error for debugging
  //   const errorMessage =
  //     error.response?.data?.message ||
  //     "An error occurred while processing your request";
  //   throw new Error(errorMessage);
  // }
  try {
    const requestBody = { motion, category, img_url };

    const data = await AXIOS_INSTANCE_AGENT.post(
      CATEGORIES_ASSISTANCE,
      requestBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch private debate list");
  }
};

export const checkMotionAgent = async (Debate_motion) => {
  // try {
  //   // Prepare the request body with the two parameters
  //   const requestBody = { Debate_motion };

  //   // Make the POST request with the requestBody
  //   const { data } = await AXIOS_INSTANCE_AGENT.post(
  //     MOTION_ASSISTANCE,
  //     requestBody
  //   );
  //   return data;
  // } catch (error) {
  //   // Provide more details about the error for debugging
  //   const errorMessage =
  //     error.response?.data?.message ||
  //     "An error occurred while processing your request";
  //   throw new Error(errorMessage);
  // }
  try {
    const requestBody = { Debate_motion };

    const data = await AXIOS_INSTANCE_AGENT.post(
      MOTION_ASSISTANCE,
      requestBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch private debate list");
  }
};
export const checkCitationAgent = async (debate_motion,url) => {
  try {
    const requestBody = { debate_motion,url };

    const data = await AXIOS_INSTANCE_AGENT.post(
      CITATION_VALIDATOR_AGENT,
      requestBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch private debate list");
  }
  // try {
  //   // Prepare the request body with the two parameters
  //   const requestBody = { url, debate_category, debate_motion, argument };

  //   // Make the POST request with the requestBody
  //   const { data } = await AXIOS_INSTANCE_AGENT.post(
  //     CITATION_VALIDATOR_AGENT,
  //     requestBody
  //   );
  //   return data;
  // } catch (error) {
  //   // Provide more details about the error for debugging
  //   const errorMessage =
  //     error.response?.data?.message ||
  //     "An error occurred while processing your request";
  //   throw new Error(errorMessage);
  // }
};
export const suggestCitationAgent = async (debate_argument) => {
  try {
    const requestBody = { debate_argument };

    const data = await AXIOS_INSTANCE_AGENT.post(
      CITATION_SUGGESTION_AGENT,
      requestBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch private debate list");
  }
  // try {
  //   // Prepare the request body with the two parameters
  //   const requestBody = { debate_argument };

  //   // Make the POST request with the requestBody
  //   const { data } = await AXIOS_INSTANCE_AGENT.post(
  //     CITATION_SUGGESTION_AGENT,
  //     requestBody
  //   );
  //   return data;
  // } catch (error) {
  //   // Provide more details about the error for debugging
  //   const errorMessage =
  //     error.response?.data?.message ||
  //     "An error occurred while processing your request";
  //   throw new Error(errorMessage);
  // }
};
export const suggestCategoryAgent = async (debate_topic) => {
  try {
    const requestBody = { debate_topic };

    const data = await AXIOS_INSTANCE_AGENT.post(
      CATEGORIES_SUGGESTION_AGENT,
      requestBody
    );
    console.log(data,"data")
    return data;
  } catch (error) {
    throw new Error("Failed to fetch debate category");
  }
  // try {
  //   // Prepare the request body with the two parameters
  //   const requestBody = { debate_argument };

  //   // Make the POST request with the requestBody
  //   const { data } = await AXIOS_INSTANCE_AGENT.post(
  //     CITATION_SUGGESTION_AGENT,
  //     requestBody
  //   );
  //   return data;
  // } catch (error) {
  //   // Provide more details about the error for debugging
  //   const errorMessage =
  //     error.response?.data?.message ||
  //     "An error occurred while processing your request";
  //   throw new Error(errorMessage);
  // }
};
export const debateSummary = async (debateId) => {
  try {
    const requestBody = { debate_id: debateId };

    const data = await AXIOS_INSTANCE_AGENT.post(
      DEBATE_SUMMARIZATION,
      requestBody
    );
    console.log(data,"data")
    return data;
  } catch (error) {
    throw new Error("Failed to fetch debate category");
  }
  // try {
  //   // Prepare the request body with the two parameters
  //   const requestBody = { debate_argument };

  //   // Make the POST request with the requestBody
  //   const { data } = await AXIOS_INSTANCE_AGENT.post(
  //     CITATION_SUGGESTION_AGENT,
  //     requestBody
  //   );
  //   return data;
  // } catch (error) {
  //   // Provide more details about the error for debugging
  //   const errorMessage =
  //     error.response?.data?.message ||
  //     "An error occurred while processing your request";
  //   throw new Error(errorMessage);
  // }
};
