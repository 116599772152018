import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { InputFocusBlue } from "../../Utils/Constant";
import { useAuth } from "../../Context/AuthContext";

const createOption = (label) => ({
  label,
  value: label,
});

const TagsInput = ({
  contacts,
  setContacts,
  selectClass,
  customStyles,
  customComponent = null,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const { user: loggedInUser } = useAuth();

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (inputValue.toLowerCase() === loggedInUser?.email?.toLowerCase()) {
          setError("You are already added please enter another email");
          setInputValue("");
          return;
        }
        if (contacts.length >= 9) {
          setError("You can only add 10 members including you");
          setInputValue("");
          return;
        }
        if (!isValidEmail(inputValue)) {
          setError("Please enter a valid email address.");
          return;
        }
        if (contacts.some((contact) => contact.value === inputValue)) {
          setInputValue("");
          setError("This email already added");
          return;
        }
        setContacts((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        setError("");

        event.preventDefault();
        break;
      default:
        setError("");
    }
  };

  const isValidEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <CreatableSelect
        components={{
          DropdownIndicator: null,
          MultiValue: customComponent,
        }}
        inputValue={inputValue}
        isClearable={false}
        isMulti
        menuIsOpen={false}
        onChange={(newValue) => setContacts(newValue)}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder="Type an email and press Enter..."
        value={contacts}
        classNamePrefix={"react-select"}
        className={`max-w-full flex-1 shadow-shadowDebate rounded-md border border-debatePalette-shadow ${InputFocusBlue} ${selectClass}`}
        styles={customStyles || {}}
      />
      {error && <p className="text-red-500 font-semibold text-sm mt-1">{error}</p>}
    </>
  );
};

export default TagsInput;
