import React, { useState } from "react";
import InitialLogo from "../../../Assets/Images/InitialLogo.png";
const ClickableChip = ({
  title,
  textClass,
  isSVG,
  SVG,
  imgClass,
  selected,
  handleClick,
}) => {
  const isSelected = selected?.categoryName === title;
  const [imgError, setImgError] = useState(false);
  const handleImageError = () => {
    setImgError(true); // Set imgError state to true when image loading fails
  };
  const DefaultStyles =
    "py-2 px-3 flex items-center gap-2 rounded-md cursor-pointer";
  const StyleConditioning = isSelected
    ? "border border-transparent bg-secondary text-white"
    : "border border-debatePalette-buttonBorder";
  return (
    <div
      className={` ${DefaultStyles} ${StyleConditioning}`}
      onClick={handleClick}
    >
      <p
        className={`${
          isSelected
            ? "text-debatePalette-background"
            : "text-debatePalette-bodyText"
        } text-base text-inherit ${textClass}`}
      >
        {title}
      </p>
      {isSVG ? (
        <SVG
          className={`${
            isSelected
              ? "text-debatePalette-background"
              : "text-debatePalette-bodyText"
          }  h-6 w-6 ${imgClass}`}
        />
      ) : imgError ? (
        <img
          src={InitialLogo}
          alt={"Logo"}
          className={`w-4 h-4 ${imgClass}`}
          onError={handleImageError}
        />
      ) : (
        <img
          src={SVG}
          className={`w-4 h-4 ${imgClass}`}
          alt={title}
          onError={handleImageError}
        />
      )}
    </div>
  );
};

export default ClickableChip;
