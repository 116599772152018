import { Card, CardBody } from "@material-tailwind/react";
import React from "react";
import { shadowBlue } from "../../../Utils/Constant";
import CheckFeat from "../../../Assets/Svg/CheckFeat.svg";

const PlanDetailCard = ({ PlanDetail }) => {
  return (
    <Card
      shadow={false}
      className={`border border-debatePalette-shadow md:min-w-[300px] ${shadowBlue}`}
    >
      <CardBody className="pt-0">
        <p className="text-2xl font-bold text-debatePalette-title my-5 text-center">
          {PlanDetail?.title}
        </p>
        <div className="flex flex-col gap-3">
          {PlanDetail?.features?.map((feature) => (
            <div className="text-sm text-gray-500 flex gap-5 " key={feature?.title}>
              <img src={CheckFeat} alt={feature?.title} />
              <span className="text-lg">{feature?.title}</span>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default PlanDetailCard;
