import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";
import Container from "../../Components/UI/Container";
import Loader from "../../Components/UI/Loader";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useGetPublicDebateList } from "../../Web-Hooks/Debate/use-debate";
import { useGetUserById } from "../../Web-Hooks/Profile/use-profile";
import Posts from "../Posts/Posts";

const PublicHome = () => {
  const location = useLocation();
  const { pathname } = location;
  const { user } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();

  const { data: userDetails } = useGetUserById(user?.userId, selectedSubWorkspace?.subWorkSpaceId, selectedWorkspace?.workSpaceId);

  const { data: PublicDebateList, isLoading: isPublicDebateListLoading } =
    useGetPublicDebateList(user?.userId, selectedWorkspace?.workSpaceId);


  return (
    <Container>
    {/* <PDFDocViewer/> */}
      {/* Title */}
      <Suspense fallback={<Loader />}>
        <Posts
          apiType={pathname}
          user={userDetails}
          isLoading={isPublicDebateListLoading}
          AllDebates={PublicDebateList}
        />
      </Suspense>
    </Container>
  );
};

export default PublicHome;
