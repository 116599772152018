import { Step, Stepper, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation, useSearchParams } from "react-router-dom";
import Container from "../../../Components/UI/Container";
import { useCreateDebateContext } from "../../../Context/CreateDebateContext";
import AddMotionArgument from "./AddMotionArgument";
import SelectCustomRounds from "./SelectCustomRounds";
import SelectType from "./SelectType";
import SelectVisibility from "./SelectVisibility";
import SelectOpponent from "./SelectOpponent";
import SelectViewer from "./SelectViewer";

export default function CreateNewDebate() {
  const location = useLocation();
  const selectedDebate = location?.state?.selectedDebate;
  const selectedCitation = location?.state?.selectedCitation;
  const search = useSearchParams()[0];
  const from = search.get("from");
  const active = search.get("activeState");
  const [activeStep, setActiveStep] = React.useState(
    active ? JSON.parse(active) : 0
  );
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [terms, setTerms] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);
  const { debateInfo, handleStateUpdate, setCitation } =
    useCreateDebateContext();
  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  useEffect(() => {
    if (from && selectedDebate) {
      handleStateUpdate({
        ...debateInfo,
        ...selectedDebate,
        Fields: selectedDebate?.Fields?.split(";").map((field) => field.trim()),
      });
    } else {
      handleStateUpdate({
        ...debateInfo,
        ...selectedDebate,
      });
      if (selectedCitation) {
        setCitation(selectedCitation);
        localStorage.setItem("Citation", JSON.stringify(selectedCitation));
      }
    } // eslint-disable-next-line
  }, [from]);

  // Define steps conditionally based on debate type
  const steps = [
    {
      label: "Debate Type",
      validation: () => !!debateInfo?.Type || "Please select Debate Type",
    },
    {
      label: "Debate Visibility",
      validation: () =>
        debateInfo?.IsDebatePrivate ||
        debateInfo?.IsDebatePublic ||
        debateInfo?.IsDebateSemiPublic
          ? true
          : "Please select Debate Visibility",
    },
  ];

  if (debateInfo?.Type === "custom") {
    steps.push({
      label: "Debate Rules",
      validation: () =>
        debateInfo?.CustomeMaxVotesToWin &&
        debateInfo?.CustomTimeFrame &&
        debateInfo?.CustomRounds
          ? true
          : "Please fill in all the custom rules",
    });
  }

  // Add final step
  steps.push({
    label: "Create Debate",
    validation: () =>
      (!!debateInfo?.SideForDebate &&
        !!debateInfo?.MotionOrClaim &&
        !!debateInfo?.CreatorOpeningArgument &&
        !!debateInfo?.Fields) ||
      "Please fill in all the required fields",
  });

  if (!debateInfo?.IsOtheruserAllow) {
    steps.push({
      label: "Invite Member",
      validation: () =>
        !!debateInfo?.NominateUserId || "Please select Opponent", // No validation for the last step
    });
  }

  if (!debateInfo?.IsOtheruserAllow && debateInfo?.IsDebatePrivate) {
    steps.push({
      label: "Invite Viewer",
      validation: () =>
        !!debateInfo?.InvitedUserId?.length > 0 || "Please select Viewer", // No validation for the last step
    });
  }

  const handleStepClick = (newStep) => {
    // If moving forward (i.e., newStep > activeStep), validate all the steps up to the new step
    if (newStep > activeStep) {
      let isValid = true;
      for (let i = activeStep; i < newStep; i++) {
        const validationMessage = steps[i].validation();
        if (validationMessage !== true) {
          isValid = false;
          toast.error(validationMessage); // Show validation error
        }
      }
      if (isValid) {
        setActiveStep(newStep); // Proceed to the clicked step
      }
    } else {
      // If moving backward, just allow the user to go back
      setActiveStep(newStep);
    }
  };

  return (
    <Container>
      <div className="w-full flex flex-col gap-10 items-start justify-start ">
        <div className="w-full p-8 md:p-4">
          <Stepper
            activeStep={activeStep}
            isLastStep={(value) => setIsLastStep(value)}
            isFirstStep={(value) => setIsFirstStep(value)}
            lineClassName="bg-primary/50"
            activeLineClassName="bg-primary"
          >
            {steps.map((step, index) => (
              <Step
                key={index}
                className="h-6 w-6 !bg-blue-gray-400 text-white cursor-pointer"
                activeClassName="ring-0 !bg-sideLayoutPrimary text-sideLayoutPrimary"
                completedClassName="!bg-sideLayoutPrimary text-sideLayoutPrimary"
                onClick={() => handleStepClick(index)}
              >
                <span className="text-white">{index + 1}</span>
                <div className="hidden lg:block lg:absolute -bottom-[2.3rem] w-max text-center lg:!text-[8px] 2xl:text-xs ">
                  <Typography variant="h6" color="black">
                    {step.label}
                  </Typography>
                </div>
              </Step>
            ))}
          </Stepper>
        </div>

        <div className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4 ">
          {" "}
          {activeStep === 0 && (
            <SelectType handleBack={handlePrev} handleNext={handleNext} />
          )}
          {activeStep === 1 && (
            <SelectVisibility handleBack={handlePrev} handleNext={handleNext} />
          )}
          {activeStep === 2 &&
            (debateInfo?.Type === "custom" ? (
              <SelectCustomRounds
                terms={terms}
                setTerms={setTerms}
                handleBack={handlePrev}
                handleNext={handleNext}
              />
            ) : (
              <AddMotionArgument
                noDebateFooter
                handleBack={handlePrev}
                handleNext={handleNext}
              />
            ))}
          {activeStep === 3 &&
            (debateInfo?.Type === "custom" ? (
              <AddMotionArgument
                noDebateFooter
                handleBack={handlePrev}
                handleNext={handleNext}
              />
            ) : (
              <SelectOpponent handleBack={handlePrev} handleNext={handleNext} />
            ))}
          {activeStep === 4 &&
            (debateInfo?.Type === "custom" ? (
              <SelectOpponent handleBack={handlePrev} handleNext={handleNext} />
            ) : (
              <SelectViewer handleBack={handlePrev} />
            ))}
          {activeStep === 5 &&
            (debateInfo?.Type === "custom" ? (
              <SelectViewer handleBack={handlePrev} />
            ) : (
              <SelectViewer handleBack={handlePrev} />
            ))}
        </div>
      </div>
    </Container>
  );
}
