import { Card, CardBody, Chip, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import MotionChip from "../../Components/UI/Chip/MotionChip";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import { getLocalTime } from "../../Utils/Helper";
import { useNavigate } from "react-router-dom";
import NoData from "../../Components/Functionality/NoData";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useGetAllFilterCategoriesCounts } from "../../Web-Hooks/Categories/use-categories";
import SearchFilter from "./SearchFilter";
import { useGetSavedDebates } from "../../Web-Hooks/Profile/use-profile";
import { getDebateDetails } from "../../api/debate/debate";

const SavedDebateList = () => {
  const { selectedSubWorkspace, subWorkspaceList } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const [filter, setFilters] = useState({
    forum: {
      label: "All forum",
      value: selectedWorkspace?.workSpaceId,
    },
    category: {
      label: "Most Recent",
      value: "",
    },
  });
  const { user: loggedInUser } = useAuth();

  const subId = filter
    ? filter?.forum?.label !== "All forum"
      ? filter?.forum?.value
      : ""
    : selectedSubWorkspace?.subWorkSpaceId || "";
  const workId = filter
    ? filter?.forum?.label === "All forum"
      ? filter?.forum?.value
      : ""
    : "";

  const categoryWorkId = filter
    ? filter?.forum?.label === "All forum"
      ? filter?.forum?.value
      : selectedWorkspace?.workSpaceId
    : selectedWorkspace?.workSpaceId;

  const { data: savedDebates } = useGetSavedDebates(
    loggedInUser?.userId,
    subId,
    workId,
    filter?.category?.value || ""
  );

  const { data: categoryCounts } = useGetAllFilterCategoriesCounts(
    loggedInUser?.userId,
    categoryWorkId,
    subId,
    "mysave"
  );

  const forumOptions =
    subWorkspaceList?.map((subWorkspace) => {
      return {
        label: subWorkspace.subWorkSpaceName,
        value: subWorkspace.subWorkSpaceId,
      };
    }) || [];

  const categories =
    categoryCounts?.map((category) => {
      return {
        label: category?.categoryName,
        value: category?.categoryName,
        count: category?.debateCount,
        type: category?.categoryName,
      };
    }) || [];

  const categoryOptions = [
    {
      label: "Most Recent",
      value: "",
    },
    {
      label: "Category",
      options: categories,
    },
  ];
  return (
    <div className="h-full flex flex-col gap-5">
      <div className="flex w-full justify-end">
        <SearchFilter
          showFilters={["forum", "category"]}
          forumOptions={[
            ...forumOptions,
            {
              label: "All forum",
              value: selectedWorkspace?.workSpaceId,
            },
          ]}
          categoryOptions={categoryOptions}
          setFilters={setFilters}
          filters={
            filter || {
              forum: {
                label: "All forum",
                value: selectedWorkspace?.workSpaceId,
              },
              category: {
                label: "Most Recent",
                value: "",
              },
            }
          }
        />
      </div>
      <div className="flex flex-col gap-2 h-full overflow-y-auto">
        {savedDebates?.length === 0 ? (
          <NoData message={"No Saved Debate"} size={"w-full h-[80%]"} />
        ) : (
          <div className="w-full h-full flex flex-col gap-5 py-3">
            {savedDebates?.map((debate) => (
              <SaveDebateCard key={debate?.debateId} debate={debate} loggedInUser={loggedInUser} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SavedDebateList;

const SaveDebateCard = ({ debate, loggedInUser }) => {
  const navigate = useNavigate();
  const handleDebateView = async (debate) => {
    const debateInfo = await getDebateDetails(
      debate?.debateId,
      loggedInUser?.userId
    );
    console.log(debateInfo);
    navigate(
      `/debate/${debate.debateId}?round=${
        debateInfo?.type === "eonian"
          ? 0
          : debateInfo?.currentRound
          ? debateInfo?.currentRound
          : 1
      }&isUpdateVote=true`,
      {
        state: { debate: debate },
      }
    );
  };
  const chipValue = debate?.isDebatePublic
    ? `Public - ${debate?.subworkspaceName}`
    : debate?.isDebatePrivate
    ? "Private"
    : `${
        debate?.subworkspaceName
          ? `Semi-Public - ${debate?.subworkspaceName}`
          : "Semi-Public"
      }`;
  return (
    <Card
      shadow={false}
      className="w-full border p-2 shadow-md"
      onClick={() => handleDebateView(debate)}
    >
      <CardBody className="w-full flex flex-col lg:flex-row gap-3 items-center lg:items-start py-3 cursor-pointer">
        <ImageOrInitials
          imageSrc={debate?.creatorImage}
          initials={debate?.creatorName || ""}
          classNameImage={" hover:scale-110 "}
          classNameInitials={" hover:scale-110 "}
          size="h-12 w-12"
          handleClick={(e) => {
            e.stopPropagation();
            navigate(
              `/profile/${debate?.creatorId}?userName=${debate?.creatorName}`
            );
          }}
        />
        <div className="flex w-full flex-col gap-2">
          <div className="px-2 flex w-full flex-col lg:flex-row items-center lg:items-start justify-between">
            <div className="flex gap-2 items-center">
              <Typography
                variant="h6"
                className="lg:text-2xl "
                color="blue-gray"
              >
                {debate?.creatorName || ""}
              </Typography>
              <Chip
                color={debate?.isDebatePublic ? "green" : "red"}
                value={chipValue}
                className={
                  debate?.isDebatePublic
                    ? "bg-public text-publicText ml-2"
                    : debate?.isDebateSemiPublic
                    ? "bg-semiPublic text-semiPublicText ml-2"
                    : "bg-private text-privateText ml-2"
                }
              />
            </div>
            <div className="flex gap-2 flex-col sm:flex-row items-center">
              <MotionChip item={debate?.categoryList} />
              <span className="w-24 text-end">
                {getLocalTime(debate?.createdAt)}
              </span>
            </div>
          </div>
          <Typography className="px-2 font-semibold">
            {debate?.motionClaim || ""}
          </Typography>
          <Typography className="px-2">
            {debate?.creatorArgument || ""}
          </Typography>
        </div>
      </CardBody>
    </Card>
  );
};
