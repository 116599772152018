import { Textarea } from "@material-tailwind/react";
import React from "react";
import { CountWords } from "../../../Utils/Helper";

export default function ArgumentEditorTextArea({
  debateInfo,
  handleStateUpdate,
  shadowNormal,
  maxLength = 500,
}) {




  const handleChange = (value) => {
    // Split the text by spaces to count the words
    const words = value.split(/\s+/).filter((word) => word.length > 0); // Filter out any empty words

    // Check if the word count exceeds 500
    if (words.length >= maxLength) {
      // Truncate the value to the first 500 words
      const truncateValue = words.slice(0, maxLength).join(" ");
      handleStateUpdate({
        ...debateInfo,
        CreatorOpeningArgument: truncateValue,
      })
      return;
    } else {
      // If it's within the limit, just update the state as usual
      handleStateUpdate({
        ...debateInfo,
        CreatorOpeningArgument: value,
      });
    }
  };


  return (
    <div className="relative overflow-auto !h-full">
      <Textarea
        resize={true}
        className={` !border-2 text-debatePalette-title text-base !border-debatePalette-buttonBorder mr-3 ${shadowNormal} focus:!border-debatePalette-shadow focus:!shadow-[0_1px_4px_1.5px_rgba(0,_139,_250,_0.25)]`}
        containerProps={{
          className: "grid h-full",
        }}
        labelProps={{
          className: "before:content-none after:content-none",
        }}
        value={debateInfo?.CreatorOpeningArgument}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
      <span className="absolute bottom-0  right-3 text-sm ">
        {maxLength - CountWords(debateInfo?.CreatorOpeningArgument)}
      </span>
    </div>
  );
}
