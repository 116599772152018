import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Button, Typography } from "@material-tailwind/react";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../Assets/Images/DB_AI.png";
import { ReactComponent as Alert } from "../../Assets/Svg/Alert.svg";
import AIArgumentSuggestion from "../../Components/Functionality/AIArgumentSuggestion";
import AICitationSuggestion from "../../Components/Functionality/AICitationSuggestion";
import AIMotionValidator from "../../Components/Functionality/AIMotionValidator";
import AIValidator from "../../Components/Functionality/AIValidtor";
import DebateHeader from "../../Components/Functionality/DebateHeader";
import { useDebounceEffect } from "../../Components/Functionality/useDebounceEffect";
import InfoPopup from "../../Components/Popup/InfoPopup";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import DebateChip from "../../Components/UI/Chip/DebateChip";
import ArgumentEditor from "../../Components/UI/Inputs/ArgumentEditor";
import DebateTextArea from "../../Components/UI/Inputs/DebateTextArea";
import { useAuth } from "../../Context/AuthContext";
import { useCreateDebateContext } from "../../Context/CreateDebateContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { formatArgumentIdeas } from "../../Utils/Constant";
import {
  argumentIdeas,
  convertArrayToString,
  convertStringToArray,
  prepareFormData,
  ValidateRequiredFields,
} from "../../Utils/Helper";
import { useArgumentAgent } from "../../Web-Hooks/Agents/use-argument-agent";
import { useCategoryAgent } from "../../Web-Hooks/Agents/use-category-agent";
import { useCitationSuggestAgent } from "../../Web-Hooks/Agents/use-citation-agent";
import { useMotionAgent } from "../../Web-Hooks/Agents/use-motion-agents";
import {
  useAddCitation,
  useAddDebateToSubWorkspace,
  useUpdateRedebateCount,
} from "../../Web-Hooks/Debate/use-debate";
import AddCategories from "./AddCategories";
import AddPhoto from "./AddPhoto";
import DebateAddCitation from "./DebateAddCitation";
import { useWorkspace } from "../../Context/WorkspaceContext";

const AddPeople = () => {
  const navigate = useNavigate();
  const { debateInfo, handleStateUpdate, handleNext, citation, handleBack } =
    useCreateDebateContext();
  const { selectedSubWorkspace } = useSubWorkspace();
  let userInfo = JSON.parse(localStorage.getItem("user"));
  const [motion, setMotion] = useState(debateInfo.MotionOrClaim);
  const [argument, setArgument] = useState(debateInfo.CreatorOpeningArgument);
  const {selectedWorkspace} = useWorkspace();
  // const { mutate, data: argumentAssistance } = useArgumentAgent();
  const { data: categoryAssistance } = useCategoryAgent(
    motion,
    debateInfo?.Fields?.length > 0
      ? convertArrayToString(debateInfo.Fields)
      : null,
    ""
  );
  const { data: motionAssistance } = useMotionAgent(motion);
  const { data: argumentAssistance } = useArgumentAgent(
    motion,
    debateInfo?.Fields?.length > 0
      ? convertArrayToString(debateInfo.Fields)
      : ""
  );
  const { data: citationAgent } = useCitationSuggestAgent(argument);

  // const { mutate: mutateValidateCitation, data: citationValidator } =
  //   useCitationCheckAgent();
  // const { mutate: mutateSuggestCitation, data: citationSuggester } =
  //   useCitationValidateAgent();
  const { user } = useAuth();
  const [Publish, setPublish] = useState(false);
  const [Invite, setInvite] = useState(false);
  const [error, setError] = useState({
    CreatorOpeningArgument: "",
    MotionOrClaim: "",
    Type: "",
    Fields: "",
    SideForDebate: "",
  });
  const [showAIValidator, setShowAIValidator] = useState(false);
  const [showMotionAISuggestions, setShowMotionAISuggestions] = useState(false);
  const [showCitationAISuggestions, setShowCitationAISuggestions] =
    useState(false);
  const [showArgumentAISuggestions, setShowArgumentAISuggestions] =
    useState(false);
  const [activeBlinkingButton, setActiveBlinkingButton] = useState({
    Category: true,
    Motion: true,
    Argument: true,
    Citation: true,
  });
  const [valid, setValid] = useState({
    Category: false,
    Motion: false,
    Argument: false,
    Citation: false,
  });
  const [image, setImage] = useState("");
  const [AIValidatorType, setAIValidatorType] = useState(null);
  const { mutateAsync: addDebate, isPending: isAddDebatePending } =
    useAddDebateToSubWorkspace();
  const { mutateAsync: addCitation, isPending: isAddCitationPending } =
    useAddCitation();
  const {
    mutateAsync: updateRedebateCount,
    isPending: isUpdateRedebateCountPending,
  } = useUpdateRedebateCount();
  const location = useLocation();
  const { state } = location;
  // console.log(state, "state");
  const [query] = useSearchParams();
  const from = query?.get("from");
  const debateId = query?.get("debateId");
  const selectedDebate = state?.selectedDebate;
  const [argumentHighlight, setArgumentHighlight] = useState("debatebase");


  useEffect(() => {
    setValid((prevState) => ({
      ...prevState, // Spread the previous state to keep other fields intact
      Category:
        debateInfo?.Fields.length > 0 ? categoryAssistance?.Matched : false, // Update only the Category field
    }));// eslint-disable-next-line
  }, [categoryAssistance]);

  // Function to determine AI validator type
  const AiMotionValidate = useCallback(() => {
    if (debateInfo?.MotionOrClaim !== "") {
      return debateInfo?.MotionOrClaim;
    }
  }, [debateInfo?.MotionOrClaim]);

  // Debounced effect
  useDebounceEffect(
    () => {
      if (AiMotionValidate !== "") {
        setValid((prevState) => ({
          ...prevState, // Spread the previous state to keep other fields intact
          Motion:
            AiMotionValidate !== ""
              ? motionAssistance?.output?.Debate_motion
              : false, // Update only the Category field
        }));
      }
      setMotion(AiMotionValidate);
    },
    1000,
    [AiMotionValidate]
  );
  // Function to determine AI validator type
  const AiArgumentValidate = useCallback(() => {
    if (debateInfo?.CreatorOpeningArgument !== "") {
      return debateInfo?.CreatorOpeningArgument;
    }
  }, [debateInfo?.CreatorOpeningArgument]);

  // Debounced effect
  useDebounceEffect(
    () => {
      setArgument(AiArgumentValidate);
    },
    1000,
    [AiArgumentValidate]
  );
  // Function to determine AI validator type

  // Debounced effect

  useEffect(() => {
    if (from === "Redebate") {
      handleStateUpdate({
        ...debateInfo,
        ...selectedDebate,
        Fields: selectedDebate?.Fields?.split(";").map((field) => field.trim()),
      });
    }// eslint-disable-next-line
  }, [from]);
// eslint-disable-next-line
  const content = {
    headLine: "Add Motion & Claims",
    baseLine: "",
    page: debateInfo?.Type === "custom" ? 4 : 3,
    pageName: "AddPeople",
  };

  const fieldsToValidate = [
    {
      name: "SideForDebate",
      displayName: "Argument",
      errorMessage: "Please select side for debate",
    },
    {
      name: "CreatorOpeningArgument",
      displayName: "Argument",
      errorMessage: "Please add a creator opening argument",
    },
    {
      name: "MotionOrClaim",
      displayName: "Debate Type",
      errorMessage: "Please enter a motion or claim",
    },
    {
      name: "Type",
      displayName: "Debate Type",
      errorMessage: "Please select a debate type",
    },
    {
      name: "Fields",
      displayName: "Categories",
      errorMessage: "Please add at least one field",
    },
  ];

  // This function is used to publish the debate or draft
  const handlePublish = async (draft = false) => {
    const draftFieldsToValidate = fieldsToValidate.filter(
      (field) =>
        field.name !== "CreatorOpeningArgument" &&
        field.name !== "SideForDebate"
    );
    try {
      const isFormValidated = ValidateRequiredFields(
        draft ? draftFieldsToValidate : fieldsToValidate,
        debateInfo,
        setError
      );
      if (!isFormValidated) return;

      const formData = prepareFormData(
        debateInfo,
        selectedSubWorkspace,
        user,
        draft
      );
      await handleDebateAndCitationAPI(formData, draft);
    } catch (error) {
      console.error("Error in handlePublish:", error);
      toast.error(
        "An error occurred while processing your request. Please try again."
      );
    }
  };

  const handleDebateAndCitationAPI = async (formData, draft = false) => {
    try {
      const { data } = await addDebate(formData);
      if (data?.debateId) {
        if (from) {
          await updateRedebateCount({
            DebateId: debateId,
            DebateCreatorUserId: user?.userId,
            RedebateCreatorUserId: user?.userId,
          });
        }
      }
      if (data && citation?.length > 0) {
        await addCitation({
          debateId: data?.debateId,
          userId: user?.userId,
          subWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
          bySide: debateInfo?.SideForDebate,
          round: 1,
          citations: citation,
        });
      }
      if (draft) {
        navigate(
          `/create-debate/add-people?from=draft&debateId=${data?.debateId}`,
          { replace: true }
        );
        toast.success("Draft saved");
        return;
      }
      navigate(debateInfo?.IsDebatePublic ? "/feed-public" : "/feed");
    } catch (error) {
      console.error("Error in handleDebateAndCitationAPI:", error);
      toast.error(
        "An error occurred while publishing the debate. Please try again."
      );
    } finally {
      setPublish(false);
      localStorage.removeItem("Debate");
      localStorage.removeItem("Citation");
    }
  };

  const handleBtnClick = () =>
    debateInfo?.IsDebatePrivate ? handleNext(`invite-member`) : setInvite(true);
  const handleInvite = () => {
    setInvite(false);
    handleNext(`invite-member`);
  };
  const isDataAdded =
    debateInfo?.MotionOrClaim &&
    debateInfo?.CreatorOpeningArgument &&
    debateInfo?.SideForDebate &&
    debateInfo?.Fields?.length > 0;

  const handleAIValidation = (type) => {
    setAIValidatorType(type);
    setShowAIValidator(true);
  };
  // Function to handle button click and update blinking state
  const handleBlinkButtonClick = (type) => {
    setActiveBlinkingButton((prevState) => ({
      ...prevState,
      [type]: !prevState[type], // Toggle the blinking state
    }));
  };


  // Function to determine if a button should blink
  const isButtonBlinking = (type) => activeBlinkingButton[type];

 

  const title = debateInfo?.IsDebatePublic
    ? selectedWorkspace?.workSpaceName
    : selectedSubWorkspace?.subWorkSpaceName;
  const heading = debateInfo?.IsDebatePublic
    ? "Public"
    : debateInfo?.IsDebateSemiPublic
    ? "Semi-Public"
    : "Private";

  const titleContext = debateInfo?.IsDebatePublic ? "debatebase" : "forum";

  return (
    <div className="px-8 my-4">
      <AIMotionValidator
        open={showMotionAISuggestions}
        debateInfo={debateInfo}
        handleClose={() => setShowMotionAISuggestions(false)}
        handleStateUpdate={handleStateUpdate}
        pauseAIButtonBlinking={() => handleBlinkButtonClick("Motion")}
        setActiveBlinkingButton={setActiveBlinkingButton}
        motionAssistance={motionAssistance}
        // citationSuggester={citationSuggester}
      />
      <AICitationSuggestion
        open={showCitationAISuggestions}
        debateInfo={debateInfo}
        handleClose={() => setShowCitationAISuggestions(false)}
        handleStateUpdate={handleStateUpdate}
        pauseAIButtonBlinking={() => handleBlinkButtonClick("Citation")}
        setActiveBlinkingButton={setActiveBlinkingButton}
        argument={argument}
        citationAgent={citationAgent}
      />
      <AIArgumentSuggestion
        open={showArgumentAISuggestions}
        debateInfo={debateInfo}
        handleClose={() => setShowArgumentAISuggestions(false)}
        handleStateUpdate={handleStateUpdate}
        pauseAIButtonBlinking={() => handleBlinkButtonClick("Argument")}
        setActiveBlinkingButton={setActiveBlinkingButton}
        argumentAssistance={argumentAssistance}
        setArgumentHighlight={setArgumentHighlight}
      />
      <AIValidator
        setArgumentHighlight={setArgumentHighlight}
        AIValidatorType={AIValidatorType}
        open={showAIValidator}
        debateInfo={debateInfo}
        handleClose={() => setShowAIValidator(false)}
        argumentAssistance={argumentAssistance}
        categoryAssistance={categoryAssistance}
        handleStateUpdate={handleStateUpdate}
        showMotionAISuggestions={() => {
          if (AIValidatorType === "Motion") {
            setShowMotionAISuggestions(true);
            setValid((prevState) => ({
              ...prevState, // Spread the previous state to keep other fields intact
              Motion:
                AiMotionValidate !== ""
                  ? motionAssistance?.output?.Debate_motion
                  : false, // Update only the Category field
            }));
          } else if (AIValidatorType === "Category") {
            handleStateUpdate({
              ...debateInfo,
              Fields:
                categoryAssistance?.Result === "Matched"
                  ? categoryAssistance?.Category
                  : convertStringToArray(
                      categoryAssistance?.["Appropriate Category"]
                    ),
            });
            setActiveBlinkingButton((prevState) => ({
              ...prevState, // Spread the previous state to keep other fields intact
              Category: true, // Update only the Category field
            }));
          } else if (AIValidatorType === "Image")
            setImage(
              "https://devsaasimgstorage.blob.core.windows.net/debatemodule/fddfdcba-8dcb-4857-8894-72412f40e362_pizza.jpg"
            );
          else if (AIValidatorType === "Argument") {
            const ideas = formatArgumentIdeas(
              argumentAssistance.arguments.Argument_ideas
            );
            // console.log(ideas, "ideas");
            // argumentRephrase

            //  Object.values(
            //   argumentAssistance?.arguments?.Argument_ideas
            // ).join("\n");
            handleStateUpdate({
              ...debateInfo,
              CreatorOpeningArgument: ideas,
            });
            setArgumentHighlight(ideas);
            // handleBlinkButtonClick("Argument");
          } else if (AIValidatorType === "Citation") {
            if (!userInfo?.isPurchasedPlanOfDebate) {
              setShowCitationAISuggestions(true);
            }

            // handleBlinkButtonClick("Citation");
          }
        }}
      />
      <DebateHeader
        content={{
          headLine: "Add Motion & Claims",
          page: debateInfo?.Type === "custom" ? 4 : 3,
        }}
      />
      <section className="w-full">
        <div className="w-full flex flex-col gap-3 md:w-4/4 lg:w-3/4 xl:w-2/4">
          <div className="flex flex-row items-start w-96">
            <AddPhoto
              debateInfo={debateInfo}
              handleStateUpdate={handleStateUpdate}
              LibraryPhotos={image}
            />
          </div>

          <div className="flex flex-row gap-2 w-full">
            <div className="flex flex-col -gap-5 w-full">
              {debateInfo?.MotionOrClaim && debateInfo?.Fields?.length > 0 ? (
                <button
                  onClick={() => {
                    handleAIValidation("Category");
                    // mutateCategory({
                    //   motion: debateInfo.MotionOrClaim,
                    //   category: debateInfo.Fields[0],
                    //   image: "",
                    // });
                  }}
                  className={` flex justify-end -pl-8 ${
                    !isButtonBlinking("Category") ? "animate-blinker" : ""
                  }`}
                >
                  <img
                    src={Logo}
                    alt="Logo"
                    height="30"
                    width="30"
                    className="bg-gray-300 rounded-full  shadow-2xl shadow-black"
                  />
                </button>
              ) : (
                <p> </p>
              )}

              <div
                className="w-full"
                onMouseEnter={() => handleBlinkButtonClick("Category")}
                onMouseLeave={() => handleBlinkButtonClick("Category")}
              >
                <AddCategories
                  debateInfo={debateInfo}
                  handleStateUpdate={handleStateUpdate}
                  disable={from === "Redebate" ? true : false}
                />
              </div>
            </div>
            <div className="pt-8">
              {valid?.Category && (
                <CheckCircleIcon color="green" className=" h-7 w-7 " />
              )}
            </div>
          </div>
          <div
            className="flex flex-row gap-2 w-full"
            onMouseEnter={() => handleBlinkButtonClick("Motion")}
            onMouseLeave={() => handleBlinkButtonClick("Motion")}
          >
            <DebateTextArea
              value={debateInfo?.MotionOrClaim}
              handleChange={(e) => {
                e.target.value === "" &&
                  setValid((prevState) => ({
                    ...prevState, // Spread the previous state to keep other fields intact
                    Motion: false,
                  }));
                handleStateUpdate({
                  ...debateInfo,
                  MotionOrClaim: e.target.value,
                });

                e.target.value !== "" &&
                  setError({ ...error, MotionOrClaim: "" });
              }}
              // onFocus={() => handleBlinkButtonClick("Motion")}
              // onBlur={() => handleBlinkButtonClick("Motion")}
              // handleOnClick={() => setActiveBlinkingButton((prevState) => ({
              //   ...prevState, // Spread the previous state to keep other fields intact
              //   Motion: true, // Update only the Category field
              // }))}
              label={
                <div className="flex flex-row justify-between">
                  <p className="w-full">
                    Write the motion or claim you want to debate!
                  </p>
                  {debateInfo?.MotionOrClaim && (
                    <button
                      onClick={() => setShowMotionAISuggestions(true)}
                      className={`text-white ${
                        !isButtonBlinking("Motion") ? "animate-blinker" : ""
                      } `}
                    >
                      <img
                        src={Logo}
                        alt="Logo"
                        height="30"
                        width="30"
                        className="bg-gray-300 rounded-full  shadow-2xl shadow-black"
                      />
                    </button>
                  )}
                </div>
              }
              maxLength={150}
              placeholder={"e.g. election_2024"}
              readOnly={from}
            />
            <div className="mt-10">
              {valid?.Motion && (
                <CheckCircleIcon color="green" className="h-7 w-7  " />
              )}
            </div>
          </div>
          <div
            className="flex flex-col gap-2 items-center mt-2"
            onMouseEnter={() =>
              setActiveBlinkingButton((prevState) => ({
                ...prevState,
                Argument: false, // Set to true when mouse enters
              }))
            }
            onMouseLeave={() =>
              setActiveBlinkingButton((prevState) => ({
                ...prevState,
                Argument: true, // Set to false when mouse leaves
              }))
            }
          >
            <div
              className={`flex flex-row justify-between w-full ${
                debateInfo?.CreatorOpeningArgument !== "" && " pr-8"
              }  `}
            >
              <label htmlFor={"argument"} className="text-lg font-semibold">
                1st Round Argument
              </label>
              {debateInfo?.MotionOrClaim && debateInfo?.Fields?.length > 0 && (
                <button
                  onClick={() => {
                    setShowArgumentAISuggestions(true);
                    // mutate({
                    //   motion: debateInfo.MotionOrClaim,
                    //   category: debateInfo.Fields[0],
                    // });
                  }}
                  className={`text-white ${
                    !isButtonBlinking("Argument") ? "animate-blinker" : ""
                  } `}
                >
                  <img
                    src={Logo}
                    alt="Logo"
                    height="30"
                    width="30"
                    className="bg-gray-300 rounded-full  shadow-2xl shadow-black"
                  />{" "}
                </button>
              )}
            </div>
            <div className="w-full flex flex-row gap-2 ">
              <div className="flex flex-row w-full gap-2 ">
                <div className="flex flex-col w-full gap-2">
                  {argumentHighlight !== "debatebase" && (
                    <div className=" flex justify-start items-start border-4 border-blue-800 p-2 w-full rounded-md py-2">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: argumentIdeas(argumentHighlight),
                        }}
                      />
                    </div>
                  )}
                  <ArgumentEditor
                    setArgumentHighlight={setArgumentHighlight}
                    debateInfo={debateInfo}
                    handleStateUpdate={handleStateUpdate}
                    argumentHighlight={argumentHighlight}
                  />
                </div>

                {/* <div
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={{ height: "150px" }}
                  className={`relative   area border-2 p-2 rounded-md w-full  ${shadowNormal} ${
                    isFocused
                      ? "border !border-debatePalette-shadow !shadow-[0_1px_4px_1.5px_rgba(0,_139,_250,_0.25)]"
                      : "border-debatePalette-buttonBorder"
                  }`}
                >
                  <HighlightWithinTextarea
                  style={{
                    minHeight: "150px",
                  }}
                    value={debateInfo?.CreatorOpeningArgument || ""}
                    onChange={(value) => {
                      let newHighLight = FindCommonSubstrings(argumentHighlight,value);
                      handleStateUpdate({
                        ...debateInfo,
                        CreatorOpeningArgument: value,
                      });
                      setArgumentHighlight(newHighLight);
                      setCharCount(value.length);
                    }}
                    placeholder=""
                    highlight={[
                      {
                        highlight: argumentHighlight,
                        className: "highlighted",
                      },
                    ]}
                  />
                  <span className="absolute bottom-0 right-3">
                    {" "}
                    {CountWords(debateInfo?.CreatorOpeningArgument)}
                  </span>{" "}
                </div> */}
                <div>
                  {debateInfo?.CreatorOpeningArgument !== "" && (
                    <CheckCircleIcon color="green" className="h-7 w-7 " />
                  )}
                </div>
              </div>
            </div>
            {error?.CreatorOpeningArgument && (
              <p className="text-red-500 text-sm font-semibold">
                {error.CreatorOpeningArgument}
              </p>
            )}
          </div>

          <div
            className="flex flex-row items-center pt-5 "
            onMouseEnter={() => handleBlinkButtonClick("Citation")}
            onMouseLeave={() => handleBlinkButtonClick("Citation")}
          >
            <div className="flex flex-col items-end w-full">
              {argument && citation.length < 2 && (
                <button
                  onClick={() => handleAIValidation("Citation")}
                  className={`rounded-full text-whiter ${
                    !isButtonBlinking("Citation") ? "animate-blinker" : ""
                  }`}
                >
                  <img
                    src={Logo}
                    alt="Logo"
                    height="30"
                    width="30"
                    className="bg-gray-300 rounded-full  shadow-2xl shadow-black"
                  />
                </button>
              )}
              <DebateAddCitation argument={argument} />

              {/* {isButtonBlinking("Citation") && (
                <CheckCircleIcon color="green" className="h-7 w-7" />
              )} */}
            </div>
          </div>

          <div className="flex flex-col gap-6 justify-center items-center py-6">
            <DebateChip
              option={[{ label: "Agree" }, { label: "Disagree" }]}
              label={debateInfo?.SideForDebate}
              setLabel={(vote) => {
                handleStateUpdate({ ...debateInfo, SideForDebate: vote });
                setError((prev) => ({ ...prev, SideForDebate: "" }));
              }}
            />
            {error?.SideForDebate && (
              <p className="text-red-500 text-sm font-semibold">
                {error.SideForDebate}
              </p>
            )}
            <Button
              variant="filled"
              className="bg-debatePalette-shadow text-base px-6 py-1.5 normal-case font-normal cursor-pointer text-debatePalette-background"
              onClick={() => handlePublish(true)}
              loading={
                isAddDebatePending ||
                isAddCitationPending ||
                isUpdateRedebateCountPending
              }
            >
              Save
            </Button>
          </div>
          <div className="flex flex-col mt-4 sm:flex-row items-center justify-between w-full">
            <Typography>
              Step {debateInfo?.Type === "custom" ? "4" : "3"} of 3
            </Typography>
            <div className="flex flex-row gap-3 content-end ">
              <BasicButton
                color={"primary"}
                className={"w-24 justify-center "}
                onClick={() =>
                  handleBack(
                    debateInfo?.Type === "custom"
                      ? "round-selection"
                      : "add-visibility"
                  )
                }
              >
                Back
              </BasicButton>
              {!debateInfo?.IsOtheruserAllow && (
                <BasicButton
                  color={"primary"}
                  className={`w-24 rounded-md justify-center ${
                    isDataAdded ? "bg-primary" : "bg-gray-400"
                  }`}
                  onClick={handleBtnClick}
                  disabled={isDataAdded}
                >
                  Invite
                </BasicButton>
              )}
              {debateInfo?.IsOtheruserAllow && (
                <BasicButton
                  color={"primary"}
                  className={`w-24 rounded-md ${
                    isDataAdded ? "bg-primary" : "bg-gray-400"
                  }`}
                  onClick={() => setPublish(true)}
                  disabled={isDataAdded}
                >
                  Publish
                </BasicButton>
              )}
            </div>
          </div>
        </div>
      </section>
      {Invite && (
        <InfoPopup
          open={Invite}
          handleClose={() => setInvite(false)}
          title={`${heading} Debate`}
          btn1={"Agree to Publish"}
          btn2={"Just Send the Invite"}
          onClickBtn1={() => {
            setInvite(false);
            setPublish(true);
          }}
          btn2Variant={"text"}
          onClickBtn2={handleInvite}
          loading1={
            isAddDebatePending ||
            isAddCitationPending ||
            isUpdateRedebateCountPending
          }
          descriptionStack={[
            "We’ll give your opponent 48 hours to accept the debate .",
            "For some reason invited opponents don’t always answer the call, but don’t worry, you still have options to stay in the game! ",
            `If your invite is not accepted within 48 hours, your debate can be automatically published to the ${title} (${heading}) feed. That means anyone in your ${titleContext} can take up your debate!`,
          ]}
        />
      )}

      {Publish && (
        <InfoPopup
          open={Publish}
          handleClose={() => setPublish(false)}
          title={
            <div className="flex items-center gap-3">
              <Alert className="h-8 w-8" />
              <span className="text-2xl font-semibold text-debatePalette-timer">
                Check this out!!
              </span>
            </div>
          }
          btn1={"Publish"}
          btn2={"Take me back"}
          btn2Variant={"text"}
          onClickBtn1={() => {
            handlePublish(false);
          }}
          onClickBtn2={() => setPublish(false)}
          loading1={
            isAddDebatePending ||
            isAddCitationPending ||
            isUpdateRedebateCountPending
          }
          descriptionStack={[
            `This debate will be published to the ${title} (${heading}) feed.`,
            `That means anyone in your ${titleContext} can take up your debate!`,
          ]}
        />
      )}

      {/* {argumentHighlight} */}
    </div>
  );
};

export default AddPeople;
