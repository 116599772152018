import { BookmarkIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkFilledIcon } from "@heroicons/react/24/solid";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Redebate } from "../../../Assets/Svg/Redebate.svg";
import { ReactComponent as Tag } from "../../../Assets/Svg/Tag.svg";
import { ReactComponent as voteIcon } from "../../../Assets/Svg/voted.svg";
import { useAuth } from "../../../Context/AuthContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { TooltipStyle } from "../../../Utils/Constant";
import { getUTCDate } from "../../../Utils/Helper";
import { useSaveDebate } from "../../../Web-Hooks/Debate/use-debate-actions";
import Tooltip from "../Tooltip/Tooltip";

const CardActionButtons = ({
  className,
  isLoading = false,
  BtnHeight,
  BtnWidth,
  istooltip = false,
  debate,
  setCitationPopup,
  setDebate
}) => {
  const { selectedSubWorkspace } = useSubWorkspace();
  const { user: loggedInUser } = useAuth();
  const navigate = useNavigate();
  const {
    mutateAsync: saveDebate,
    //  isPending: savingDebate
  } = useSaveDebate();

  // const [citationPopup, setCitationPopup] = useState(false);
  const PostActionIcon = [
    {
      SVG: Redebate,
      action: "redebate",
      count: debate?.reDebateCount,
      toolcontent: "Re-debate",
      isSvg: true,
      handleClick: (e) => {
        e.stopPropagation();
        const categoryString = Array.isArray(debate?.category) ? debate.category.map((cat) => cat.fieldName).join(";") : debate?.category;
        navigate(
          `/create-debate?from=Redebate&activeState=2&debateId=${debate?.debateId}`,
          {
            state: {
              selectedDebate: {
                MotionOrClaim: debate?.motionOrClaim,
                Fields: categoryString,
                IsDebateSemiPublic: debate?.isDebateSemiPublic,
                IsDebatePublic: debate?.isDebatePublic,
                IsDebatePrivate: debate?.isDebatePrivate,
                Type: debate?.debateType,
              },
            },
            replace: true
          }
        );
      },
      isFilled: false,
    },
    {
      SVG: Tag,
      action: "tagCitation",
      count: debate?.totalCitationCount,
      toolcontent: "Tag citation",
      isSvg: true,
      handleClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        setCitationPopup(true);
        setDebate(debate);
      },
      isFilled: false,
    },
    {
      SVG: voteIcon,
      action: "vote",
      count: debate?.voteForDebate,
      toolcontent: "Vote",
      isSvg: true,
      handleClick: () => {},
      isFilled: false,
    },
    {
      SVG: BookmarkIcon,
      SVGFilled: BookmarkFilledIcon,
      action: "save",
      count: null,
      toolcontent: "save",
      isSvg: true,
      handleClick: async() => {
        saveDebate({
          SubWorkSpaceId: debate?.subworkspaceId || selectedSubWorkspace?.subWorkSpaceId,
          DebateId: debate?.debateId,
          UserId: loggedInUser?.userId,
          CreatedAt: getUTCDate(),
        }).then((res) => {
          if (res) {
            toast.success(res.message);
          }
        });
      },
      isFilled: debate?.isDebateSaved,
    },
    // {
    //   SVG: share,
    //   action: "share",
    //   count: null,
    //   toolcontent: "share",
    //   isSvg: true,
    //   handleClick: () => {},
    //   isFilled: false,
    // },
  ];
  return (
    <>
      {PostActionIcon.map((action, i) => (
        <Tooltip
          content={action.toolcontent}
          className={`${TooltipStyle} ${!istooltip && "hidden"} text-nowrap`}
          key={i}
        >
          <button
            className={`bg-transparent group text-black rounded-sm p-2 ${
              isLoading ? "cursor-not-allowed" : "cursor-pointer"
            } flex justify-center gap-2 items-center ${BtnHeight} ${BtnWidth} ${className} `}
            onClick={(e) => action.handleClick(e)}
            key={i}
          >
            {action.isImage && (
              <img
                src={action.SVG}
                alt={action.SVG}
                className="text-iconPalette-base h-6 w-6"
              />
            )}
            {action.isFilled
              ? action.isSvg && (
                  <action.SVGFilled className="text-iconPalette-base h-6 w-6 group-hover:text-debatePalette-title group-hover:scale-125" />
                )
              : action.isSvg && ( 
                  <action.SVG className="text-iconPalette-base h-6 w-6 group-hover:text-debatePalette-title group-hover:scale-125" />
                )}
            {action.count && (
              <span className="font-medium text-iconPalette-base">
                {action.count}
              </span>
            )}
          </button>
        </Tooltip>
      ))}
    </>
  );
};

export default CardActionButtons;
