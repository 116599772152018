import {
  Card,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  Radio,
  Typography,
} from "@material-tailwind/react";
import { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Report } from "../../../Assets/Svg/Report.svg";
import { useAuth } from "../../../Context/AuthContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import { REPORTS_MESSAGES } from "../../../Utils/Constant";
import {
  useBlockUser,
  useReportUser,
} from "../../../Web-Hooks/Profile/use-profile";
import EditableBodyPopup from "../../Popup/EditableBodyPopup";
import InfoPopup from "../../Popup/InfoPopup";
import DebateTextArea from "../Inputs/DebateTextArea";
import MenuListRender from "./MenuListRender";

function ThreeDotIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
      />
    </svg>
  );
}

export function ThreeDotMenu({ user, debateId, type = "debate" }) {
  const { user: loggedInUser } = useAuth();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const { mutate: blockUser } = useBlockUser();
  const [open, setOpen] = useState(false);
  const [reportPopup, setReportPopup] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [otherReason, setOtherReason] = useState("");
  const { mutateAsync: reportUser, isPending: isReporting } = useReportUser();
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const navigate = useNavigate();

  const sameUser = user === loggedInUser?.userId;
  const PostThreeDotMenu = [
    // {
    //   SVG: FilledShare,
    //   item: `${debateId ? "Share Debate" : "Share Profile"}`,
    //   isHRline: false,
    //   value: "shareDebate",
    //   subOption: [
    //     {
    //       SVG: CopyLink,
    //       item: "Copy Link",
    //       isHRline: false,
    //       value: "copyLink",
    //       handleClick: (e) => handleClick(e, "share"),
    //     },
    //   ],
    // },
    {
      SVG: Report,
      item: "Report",
      isHRline: false,
      isRedText: true,
      value: "report",
      handleClick: (e) => handleClick(e, "report"),
      isLoggedInUser: user === loggedInUser?.userId,
    },
    // {
    //   SVG: Block,
    //   item: "Block User",
    //   isHRline: false,
    //   value: "blockUser",
    //   handleClick: (e) => handleClick(e, "blockUser"),
    //   isLoggedInUser: user === loggedInUser?.userId,
    // },
  ];

  const handleClick = (e, value) => {
    if (value === "shareProfile") {
      console.log("Share Profile");
    } else if (value === "report") {
      setReportPopup(true);
    } else if (value === "blockUser") {
      setConfirmPopup(true);
      setPopupTitle("Block");
    }
    e.stopPropagation();
    setOpen(false);
  };

  const handleBlockUser = async (e) => {
    e.stopPropagation();
    try {
      const blockBody = {
        BlockedUserId: user,
        // BlockFrom: "Admin",
        BlockFrom: "UserToUser",
        // BlockFrom: "DebateWise",
        WorkSpaceId: selectedWorkspace?.workSpaceId,
        BlockedByUserId: loggedInUser?.userId,
        // DebateId: "7f691cb3-5c7d-436f-b174-b58442e900b4",
      };

      if (Object.values(blockBody).some((value) => !value)) {
        toast.error(`Failed to block ${type}, please try again later.`);
        setConfirmPopup(false);
        setPopupTitle("");
        return;
      }

      await blockUser(blockBody);
      toast.success("User blocked successfully");
      setConfirmPopup(false);
      setPopupTitle("");
      navigate("/feed-public");
    } catch (error) {
      console.log("Something went wrong");
      setConfirmPopup(false);
      setPopupTitle("");
    }
  };

  const handleReportUser = async (e) => {
    e.stopPropagation();
    const reportObj = {
      UserId: loggedInUser?.userId,
      SubWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
      ReportReason:
        selectedReport === "Something else" ? otherReason : selectedReport,
    };
    if (Object.values(reportObj).some((value) => !value)) {
      toast.error(`Failed to report ${type}, please try again later.`);
      setConfirmPopup(false);
      setPopupTitle("");
      setReportPopup(false);
      return;
    }

    reportObj.DebateId = debateId;

    reportUser(reportObj)
      .then((res) => {
        if (res) {
          toast.success(`Successfully reported ${type}`);
        }
      })
      .catch((error) => {
        toast.error(`Failed to report ${type}, please try again later.`);
      })
      .finally(() => {
        setConfirmPopup(false);
        setPopupTitle("");
        setReportPopup(false);
      });
  };

  const handleReportSelect = (e, value) => {
    e.stopPropagation();
    setSelectedReport(value);
  };

  return (
    <Fragment>
      {confirmPopup && (
        <InfoPopup
          open={confirmPopup}
          handleClose={(e) => {
            e.stopPropagation();
            setConfirmPopup(false);
            setReportPopup(false);
          }}
          btn1={"cancel"}
          onClickBtn1={(e) => {
            e.stopPropagation();
            setConfirmPopup(false);
            setReportPopup(false);
          }}
          btn2={"Confirm"}
          onClickBtn2={
            popupTitle === "Block" ? handleBlockUser : handleReportUser
          }
          description={`Are you sure you want to ${popupTitle} this ${
            popupTitle === "Block" ? "user" : `${type}`
          }?`}
          loading2={isReporting}
          title={popupTitle}
        />
      )}
      {reportPopup && (
        <EditableBodyPopup
          size={"sm"}
          title={"Report"}
          open={reportPopup}
          handleClose={(e) => {
            e.stopPropagation();
            setReportPopup(false);
            setPopupTitle("");
          }}
          btn2={"Report"}
          onClickBtn2={(e) => {
            e.stopPropagation();
            setReportPopup(false);
            setConfirmPopup(true);
            setPopupTitle("Report");
          }}
          disableBtn={
            selectedReport === "Something else"
              ? otherReason
                ? false
                : true
              : !selectedReport
          }
          children={
            <div className="overflow-y-auto">
              <Typography variant="lead" color="black" className="font-bold">
                Why are you reporting this {type}?
              </Typography>
              <Typography variant="small" color="black" className="font-normal">
                Your report is anonymous, excepts if you're reporting an
                intellectual property infringement.
              </Typography>
              <Card className="h-72 overflow-y-auto">
                <List className="">
                  {REPORTS_MESSAGES.map((item) => (
                    <ListItem
                      className="py-1 flex flex-col gap-1 items-start"
                      key={item?.id}
                      onClick={(e) => handleReportSelect(e, item?.message)}
                    >
                      <Radio
                        ripple={false}
                        label={item?.message}
                        checked={selectedReport === item?.message}
                        onChange={(e) => handleReportSelect(e, item?.message)}
                        color="blue"
                        name="report"
                      />
                      {selectedReport === "Something else" &&
                        selectedReport === item?.message && (
                          <DebateTextArea
                            maxLength={100}
                            placeholder="please write your reason here"
                            value={otherReason}
                            handleChange={(e) => setOtherReason(e.target.value)}
                          />
                        )}
                    </ListItem>
                  ))}
                </List>
              </Card>
            </div>
          }
        />
      )}
      <Menu
        placement="bottom-end"
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
        open={open}
        handler={setOpen}
      >
        <MenuHandler>
          <IconButton
            variant="text"
            className={`${sameUser ? "hidden" : "block"} `}
          >
            <ThreeDotIcon />
          </IconButton>
        </MenuHandler>
        <MenuList className="!z-40">
          {PostThreeDotMenu.map((option, i) => (
            <MenuListRender
              ref={option.value}
              option={option}
              key={option.value}
            />
          ))}
        </MenuList>
      </Menu>
    </Fragment>
  );
}
